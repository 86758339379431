import React from 'react';
import API from "../../api";
import successAlert from "../../services/AlertS/sucess";
import errorAlert from "../../services/AlertS/error";

export const TwoFactorAuth = ({headers}) => {
    const [otp, setOtp] = React.useState('');
    const [SecreCode, setSecreCode] = React.useState('');
    const [googleAuthData, setGoogleAuthData] = React.useState(false);
    React.useEffect(() => {

        const req = async (e) => {

            try {
                const res = await API.post('app_2fa_auth', {}, {headers})
                setSecreCode(res.data.secret)
                setGoogleAuthData(res.data.hasOptConfig)
                // Display QR code image
                const img = document.getElementById('qrcode');
                img.src = res.data.qrcode;
            } catch (e) {

            }
        }
        req()

    }, []);
    const handleChange = (e) => {
        setOtp(e.target.value);
    };
    const verifyCode = (code) => {
        const req = async (e) => {
            try {
                const res = await API.post('google_authenticator_processing', {
                    optcode: otp,
                    secret: SecreCode
                }, {headers})
                setGoogleAuthData(true);
                successAlert("Validation complete", res.data)
            } catch (e) {
                errorAlert('Validation Error', e.message)
            }
        }
        req()

    };
    return (
        <div className="card">
            <div className="card-body">
                <h2 className="text-center">Google Authenticator 2FA</h2>
                {
                    googleAuthData !== true ?
                        (
                            <div className="text-center">
                                <p><small>Scan this QRCODE</small></p>
                                <img id="qrcode" className="d-block mx-auto mt-3 mb-3" alt="QR Code"/>
                                <p><small>OR ENTER </small></p>
                                <h3 className="text-center">{SecreCode}</h3>
                                <p><small>SECRET CODE IN AUTHENTICATOR APP</small></p>
                                <h4 className="fw-bold">{googleAuthData.secret}</h4>
                                <p className="text-center">
                                    To enable two-step verification, you need to have a mobile device with a compatible
                                    authentication app installed. This app is necessary for the proper functioning of
                                    2-Step Verification.
                                </p>
                                <p>For more information on Google two-factor authentication visit <a
                                    href=" https://www.google.com/landing/2step/?hl=en"><u>
                                    https://www.google.com/landing/2step/?hl=en</u></a></p>
                                <div className="form-floating mt-3 mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={otp}
                                        onChange={handleChange}
                                        id="floatingInput"
                                        placeholder="ENTER OPT CODE"
                                    />
                                    <label htmlFor="floatingInput">ENTER OPT CODE</label>
                                </div>
                                <button className="btn btn-success" onClick={verifyCode}>
                                    Validate
                                </button>
                            </div>
                        )
                        :
                        (
                            <div className="text-center">
                                <p><small>YOU HAVE ALREADY CONFIGURED YOUR 2FA</small></p>
                                <h4 className="my-5">RESET YOUR 2FA BY CLICKING THE BUTTON BELOW </h4>
                                <button disabled="disabled" className="btn btn-primary mb-4">RESET</button>
                            </div>

                        )
                }

            </div>
        </div>
    )
}
export default TwoFactorAuth;