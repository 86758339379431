import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        userInfo: null,
        userTheme: false,
        reloadNotification: false,
        toggleLeftside: false,
        isFetching: false,
        error: false,
    },
    reducers: {
        updateuserinfo: (state, action) => {
            state.userInfo = action.payload;
        },
        updateUserTheme: (state, action) => {
            state.userTheme = action.payload;
        },
        leftSidetoggled: (state, action) => {
            state.toggleLeftside = action.payload;
        },
        reloadNotificationCounter: (state, action) => {
            state.reloadNotification = action.payload;
        }

    },
});

export const {
    updateuserinfo,updateUserTheme,leftSidetoggled,reloadNotificationCounter
} = userSlice.actions;
export default userSlice.reducer;