import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import ScriptListContent from "./ScriptListContent";
import React from "react";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";

export const ScriptList = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    setPageTitle('Scripts list');
    UnathorizedRedirect(personinfo, 'Lecturer');
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <ScriptListContent personinfo={personinfo}/>
        </div>
    )
}
export default ScriptList;