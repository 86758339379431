import {Button, Modal} from "react-bootstrap";
import React from "react";
import API from "../../api";
import successAlert from "../../services/AlertS/sucess";
import errorAlert from "../../services/AlertS/error";
import warningAlert from "../../services/AlertS/warning";

export const CreateAssetModal = ({
                                     token,
                                     assetsCategory,
                                     modalShow,
                                     initForm,
                                     formData,
                                     setFormData,
                                     handleToggleModal,
                                     onAssetCreated, // Add the onAssetCreated prop
                                 }) => {

    const fileInputRef = React.useRef(null);
    const categoryOptions = assetsCategory.map((wallet) => (
        <option value={wallet.id} selected={wallet.id === formData.category && 'selected'}>
            {wallet.libelle}
        </option>
    ));

    function handleChange(event) {
        const {name, value, type, checked} = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file.size > 8000000) {
            warningAlert("File upload error", "Max file size upload is 8MB!");
            return false;
        }

        const fileData = new FormData();
        fileData.append("file", file);

        setFormData((prevState) => ({
            ...prevState,
            file: fileData.get("file"),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const req = async (e) => {
            try {
                const reponse = await API.post(
                    "save_library_assets",
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                successAlert("Nice one", reponse.data);

                // Invoke the onAssetCreated callback
                onAssetCreated();
                setFormData(initForm);
            } catch (e) {
                // console.log(e);
                errorAlert("Oops an error occurred", e.response.data);
            }
        };
        req();
    };

    return (
        <Modal show={modalShow} onHide={handleToggleModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="d-flex w-100">
                        <span>Create library asset</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} autoComplete="off" className="form-body">
                    <div className="form-floating mb-3">
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            required={true}
                            onChange={handleChange}
                            name="title"
                            value={formData.title}
                            placeholder="title"
                        />
                        <label htmlFor="title">Asset Title</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select
                            required={true}
                            onChange={handleChange}
                            name="category"
                            value={formData.category}
                            className="form-select"
                            id="category"
                            aria-label="Floating label select example"
                        >
                            <option value="">Select a category</option>
                            {categoryOptions}
                        </select>
                    </div>
                    <div className="form-floating mb-3">
            <textarea
                onChange={handleChange}
                name="note"
                value={formData.note}
                className="form-control"
                placeholder="Leave a comment here"
                id="floatingTextarea"
            ></textarea>
                        <label htmlFor="floatingTextarea">
                            Note <small>[Optional]</small>
                        </label>
                    </div>
                    <div className="d-flex mb-3">
                        <input
                            onChange={handleFileUpload}
                            name="file"
                            type="file"
                            id="file" required={true}
                            ref={fileInputRef}
                            accept=".pdf,.pptx,.doc,.docx"
                        />
                        <label htmlFor="file" className="dynmatxt fw-bold">
                            Formats allowed: .pdf,.pptx,.doc,.docx [Max size: 8MB]
                        </label>
                    </div>
                    <Button
                        variant="primary"
                        type="submit"
                        className="float-end btn-primary"
                    >
                        Create
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateAssetModal;
