import React, {useEffect, useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import DataTable from "react-data-table-component";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import TopMeunSection from "../components/Blocks/TopMeunSection";
import {Context} from "../context/Contexts";
import API from "../api";
import errorAlert from "../services/AlertS/error";
import NotificationTable from "../components/NotificationBoard/NotificationTable";
import setPageTitle from "../services/TitleService";
import {reloadNotificationCounter} from "../redux/userRedux"

export const UserNotifications = () => {
    const dispatch = useDispatch();
    setPageTitle("My notifications");
    const personinfo = useSelector((state) => state.user.userInfo);
    const {user} = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const counter= useSelector(state => state.user.reloadNotificationCounter);
    const [notifications, setNotifications] = useState([]);
    const [reRender, setReRender] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await API.post("get_user_notification", {}, {headers});
                setNotifications(res.data);
            } catch (error) {
                errorAlert("Oops", "Error while getting data!");
            }
        };

        fetchData();
    }, [reRender]);

    const apptheme = useSelector((state) => state.user.userTheme);
    const handleMarkAllRead = async () => {
        try {
            await API.post("mark_user_notification", {}, {headers});
            setReRender(prevState => !prevState)
            dispatch(reloadNotificationCounter(!counter))

        } catch (error) {
            errorAlert("Oops", "Error while getting data redux!");
        }
    }
    const ExpandedComponent = ({data}) => (
        <div>
            <p>
                Lecturer in charge: <b>{data.object}</b>
            </p>
            <p>
                Description: <b>{data.description}</b>
            </p>


        </div>
    );
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <div className="content">
                <TopMeunSection personinfo={personinfo}/>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body overflow-auto">
                                    <button onClick={handleMarkAllRead} className="btn btn-sm btn-outline-info mb-4">
                                        Mark all as read{" "}
                                        <i className="fas fa-envelope-open fa-lg"></i>
                                    </button>
                                    {notifications.length > 0 ? (
                                        <DataTable
                                            responsive
                                            striped
                                            theme={apptheme ? "dark" : undefined}
                                            direction="auto"
                                            subHeaderAlign="right"
                                            subHeaderWrap
                                            expandableRows
                                            expandableRowsComponent={ExpandedComponent}
                                            columns={NotificationTable({notifications})}
                                            data={notifications}
                                            pagination
                                        />
                                    ) : (
                                        <p>No notifications found.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserNotifications;
