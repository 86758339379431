import React, {useState} from "react";
import {Context} from "../../../context/Contexts";
import API from "../../../api";
import successAlert from "../../../services/AlertS/sucess";
import errorAlert from "../../../services/AlertS/error";
import Slider from "react-slick";
import WalletCard from "../../Dashboard/WalletCard";
import TopMeunSection from "../../Blocks/TopMeunSection";
import {FeeModal} from "./FeeModal";
import {useSelector} from "react-redux";

export const WithdrawFormContent = ({personinfo}) => {
    const initForm = {
        note: "",
        wallet: "",
        selectFee: null,
        withdrawType: personinfo.personType === "Lecturer" ? "simple" : "",
        optcode: "",
        amount: ""
    }
    const [formData, setFormData] = React.useState(initForm)
    const [userwallets, setUserwallets] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false)
    const [ChangeWalletState, setChangeWalletState] = React.useState(true);
    const [selectedFee, setSelectedFee] = useState({uuid: null, feetype: null});

    const {user} = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user?.token}`,
    };

    const [modalShow, setModalShow] = React.useState(false);
    const handleToggleModal = () => {
        setModalShow(prevState => {
            return (!prevState)
        });
    };

    const resetForm=()=>{
        setChangeWalletState(prevState => {
            return (!prevState)
        })
        setFormData(initForm);
        setIsLoading(prevState => !prevState);
    }
    function handleChange(event) {
        const {name, value, type, checked} = event.target
        value === 'feePayment' && handleToggleModal()
        setFormData(prevState => {
            return {
                ...prevState, [name]: type === "checkbox" ? checked : value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const req = async (e) => {
            try {
                const reponse = await API.post('withdrawfund', {formData}, {headers});
                successAlert('Nice one', reponse.data)
                resetForm()
            } catch (e) {
                errorAlert('Transaction Error', e.response.data)
                resetForm()
            }
        }
        req()
    }
    const walletSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    React.useEffect(() => {
        API.post('wallet_info', {}, {headers})
            .then(res => setUserwallets(res.data))
        ;
    }, [ChangeWalletState]);

    const walletElement = (
        userwallets.length > 1 ?
            <Slider {...walletSettings}>
                {userwallets.map((item, index) => (
                    <WalletCard key={index} {...item} />
                ))}
            </Slider>
            :
            userwallets.map((item, index) => (
                <WalletCard key={index} {...item} />
            ))
    );

    const walletOptions = userwallets.map((wallet) => (
        <option key={wallet.uuid} value={wallet.uuid}>
            {wallet.libelle} -> {parseInt(wallet.balance).toLocaleString()}
        </option>
    ));


    const apptheme = useSelector(state => state.user.userTheme);
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12 col-lg-6 offset-lg-3">
                        <div className="card">
                            <div className="card-body">

                                {personinfo.personType !== "Lecturer" &&
                                    <FeeModal headers={headers} modalShow={modalShow} setFormData={setFormData}
                                              handleToggleModal={handleToggleModal}
                                              apptheme={apptheme} ChangeWalletState={ChangeWalletState}
                                              setSelectedFee={setSelectedFee}/>
                                }


                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <h3 className="mb-4">Withdraw Funds
                                        <div className="d-inline float-end">
                                            <a href="/transaction-history" className="notificationboard-viewmore">
                                                <span>View Transactions</span> <i
                                                className="fas fa-fas fa-list"></i></a>
                                        </div>

                                    </h3>
                                    <small>MY WALLET(S)</small>
                                    {walletElement}
                                    <hr/>

                                    {personinfo.personType !== "Lecturer" && (
                                        <>
                                            <label className="control-label dynmatxt">
                                                What type of withdrawal do you want to make?
                                            </label>
                                            <div className="d-flex justify-content-between w-100 mt-4 mb-3">
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="transfer_personType_0"
                                                        name="withdrawType"
                                                        required="required"
                                                        onChange={handleChange}
                                                        checked={formData.withdrawType === "simple"}
                                                        value="simple"
                                                    />
                                                    <label htmlFor="transfer_personType_0" className="required ps-2">
                                                        Simple Withdrawal
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="transfer_personType_1"
                                                        name="withdrawType"
                                                        checked={formData.withdrawType === "feePayment"}
                                                        required="required"
                                                        onChange={handleChange}
                                                        value="feePayment"
                                                    />
                                                    <label htmlFor="transfer_personType_1"
                                                           className="required cursor ps-2">
                                                        Pay A Fee
                                                    </label>
                                                </div>
                                            </div>
                                            {selectedFee.uuid !== null && (
                                                <div className="my-3">
                                                    SELECTED FEE: <b>{selectedFee.feetype}</b>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control numberField" id="recieverCode"
                                               name="amount"
                                               required="required"
                                               value={formData.amount} onChange={handleChange}
                                               placeholder="&nbsp;"/>
                                        <label htmlFor="recieverCode">Amount</label>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <select
                                            className="form-select"
                                            id="wallet"
                                            required="required"
                                            name="wallet"
                                            value={formData.wallet}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select a wallet</option>
                                            {walletOptions}
                                        </select>
                                        {/*<label htmlFor="wallet"></label>*/}
                                    </div>

                                    <div className="form-floating mb-3">
                                        <textarea className="form-control" name="note"
                                                  placeholder="Leave a comment here"
                                                  value={formData.note} onChange={handleChange}
                                                  id="narration"></textarea>
                                        <label htmlFor="narration">Comments</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control numberField" id="recieverCode"
                                               name="optcode"
                                               required="required"
                                               value={formData.optcode} onChange={handleChange}
                                               placeholder="&nbsp;"/>
                                        <label htmlFor="recieverCode">OPT CODE</label>
                                    </div>
                                    <button className="btn btn-primary mt-3 w-100" disabled={isLoading}>Submit {isLoading ?
                                        <i className="fas fa-circle-notch fa-spin"></i> : <i
                                            className="las la-paper-plane"></i>}</button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WithdrawFormContent;