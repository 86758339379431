import { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import FaviconService from "./services/FaviconService";
import { useReplaceLettersWithNumbers } from './services/replaceLettersWithNumbers.js'
import {
    LoginForm, Logout, Dashboard,ProfilePage, Course, ELibrary, Fee, Assessments, ProtectedRoute, FeeDetail, TransactionHistory, TransferForm, WithdrawalForm, QuestionPage, NotificationBoardPage, NotificationBoardDetailPage, Scoresheet, AssessmentScore, ScriptList, MarkingScript, AddQuestion, EditQuestion, AiView, Scoreboard, Affiliates, UserNotifications, RegistrationForm,RegistrationStatus,Unauthorized,TopupWallet,NotFound
} from './pages';

import "./firebase";


function App() {
    // Listener
    useReplaceLettersWithNumbers();
    const Layout = () => {
        return (
            <div className="app">
                <FaviconService/>
                <Outlet/>
            </div>
        );
    };

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout/>,
            errorElement: (
                <Suspense fallback={<div></div>}>
                    <NotFound/>
                </Suspense>
            ),
            children: [
                {
                    path: "/un-authorized",
                    element: (
                        <Suspense fallback={<div></div>}>
                            <Unauthorized/>
                        </Suspense>
                    ),
                },
                {
                    path: "/",
                    element: (
                        <Suspense fallback={<div></div>}>
                            <LoginForm/>
                        </Suspense>
                    ),
                },
                {
                    path: "/login",
                    element: (
                        <Suspense fallback={<div></div>}>
                            <LoginForm/>
                        </Suspense>
                    ),
                },
                {
                    path: "/application-form",
                    element: (
                        <Suspense fallback={<div></div>}>
                            <RegistrationForm/>
                        </Suspense>
                    ),
                },{
                    path: "/application-form/sponsor/:sponsorCode",
                    element: (
                        <Suspense fallback={<div></div>}>
                            <RegistrationForm/>
                        </Suspense>
                    ),
                },
                {
                    path: "/application-form/status/:status",
                    element: (
                        <Suspense fallback={<div></div>}>
                            <RegistrationStatus/>
                        </Suspense>
                    ),
                },
                {
                    path: "/logout",
                    element: (
                        <Suspense fallback={<div></div>}>
                            <Logout/>
                        </Suspense>
                    ),
                },
                {
                    path: "/dashboard",
                    element: (
                        <ProtectedRoute>
                            <Dashboard/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/my-profile",
                    element: (
                        <ProtectedRoute>
                            <ProfilePage/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/my-affilitates",
                    element: (
                        <ProtectedRoute>
                            <Affiliates/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/top-wallet",
                    element: (
                        <ProtectedRoute>
                            <TopupWallet/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/courses",
                    element: (
                        <ProtectedRoute>
                            <Course/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/add-question/:id/:asstype",
                    element: (
                        <ProtectedRoute>
                            <AddQuestion/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/add-question/:id/:asstype",
                    element: (
                        <ProtectedRoute>
                            <AddQuestion/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/edit-question/:id",
                    element: (
                        <ProtectedRoute>
                            <EditQuestion/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/take-assessment/:id",
                    element: (
                        <ProtectedRoute>
                            <QuestionPage/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/e-library",
                    element: (
                        <ProtectedRoute>
                            <ELibrary/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/assessments",
                    element: (
                        <ProtectedRoute>
                            <Assessments/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/scoresheet/:id",
                    element: (
                        <ProtectedRoute>
                            <Scoresheet/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/score-board",
                    element: (
                        <ProtectedRoute>
                            <Scoreboard/>
                        </ProtectedRoute>
                    ),
                },


                {
                    path: "/get-my-score/:id",
                    element: (
                        <ProtectedRoute>
                            <AssessmentScore view={false}/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/view-my-score/:id",
                    element: (
                        <ProtectedRoute>
                            <AssessmentScore view={true}/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/list-scripts",
                    element: (
                        <ProtectedRoute>
                            <ScriptList/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/mark-scripts/:id",
                    element: (
                        <ProtectedRoute>
                            <MarkingScript/>
                        </ProtectedRoute>
                    ),
                },{
                    path: "/notification-board-message/:id",
                    element: (
                        <ProtectedRoute>
                            <NotificationBoardDetailPage/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/notification-board-messages",
                    element: (
                        <ProtectedRoute>
                            <NotificationBoardPage/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/fees",
                    element: (
                        <ProtectedRoute>
                            <Fee/>
                        </ProtectedRoute>
                    ),
                },

                {
                    path: "/fees-detail/:id",
                    element: (
                        <ProtectedRoute>
                            <FeeDetail/>
                        </ProtectedRoute>
                    ),
                },

                {
                    path: "/transaction-history",
                    element: (
                        <ProtectedRoute>
                            <TransactionHistory/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/transfer-funds",
                    element: (
                        <ProtectedRoute>
                            <TransferForm/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/withdraw-funds",
                    element: (
                        <ProtectedRoute>
                            <WithdrawalForm/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/ai",
                    element: (
                        <ProtectedRoute>
                            <AiView/>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "/my-notifications",
                    element: (
                        <ProtectedRoute>
                            <UserNotifications/>
                        </ProtectedRoute>
                    ),
                },

            ]
        }
    ]);
    return <RouterProvider router={router}/>;
}

export default App;



// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <Layout/>,
//         errorElement: (
//             <Suspense fallback={<div></div>}>
//                 <NotFound/>
//             </Suspense>
//         ),
//         children: [
//             {
//                 path: "/un-authorized",
//                 element: (
//                     <Suspense fallback={<div></div>}>
//                         <Unauthorized/>
//                     </Suspense>
//                 ),
//             },
//             {
//                 path: "/",
//                 element: (
//                     <Suspense fallback={<div></div>}>
//                         <LoginForm/>
//                     </Suspense>
//                 ),
//             },
//             {
//                 path: "/login",
//                 element: (
//                     <Suspense fallback={<div></div>}>
//                         <LoginForm/>
//                     </Suspense>
//                 ),
//             },
//             {
//                 path: "/application-form",
//                 element: (
//                     <Suspense fallback={<div></div>}>
//                         <RegistrationForm/>
//                     </Suspense>
//                 ),
//             }, {
//                 path: "/application-form/sponsor/:sponsorCode",
//                 element: (
//                     <Suspense fallback={<div></div>}>
//                         <RegistrationForm/>
//                     </Suspense>
//                 ),
//             },
//             {
//                 path: "/application-form/status/:status",
//                 element: (
//                     <Suspense fallback={<div></div>}>
//                         <RegistrationStatus/>
//                     </Suspense>
//                 ),
//             },
//             {
//                 path: "/logout",
//                 element: (
//                     <Suspense fallback={<div></div>}>
//                         <Logout/>
//                     </Suspense>
//                 ),
//             },
//             {
//                 path: "/dashboard",
//                 element: (
//                     <ProtectedRoute>
//                         <Dashboard/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/my-profile",
//                 element: (
//                     <ProtectedRoute>
//                         <ProfilePage/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/my-affilitates",
//                 element: (
//                     <ProtectedRoute>
//                         <Affiliates/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/top-wallet",
//                 element: (
//                     <ProtectedRoute>
//                         <TopupWallet/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/courses",
//                 element: (
//                     <ProtectedRoute>
//                         <Course/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/add-question/:id/:asstype",
//                 element: (
//                     <ProtectedRoute>
//                         <AddQuestion/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/add-question/:id/:asstype",
//                 element: (
//                     <ProtectedRoute>
//                         <AddQuestion/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/edit-question/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <EditQuestion/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/take-assessment/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <QuestionPage/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/e-library",
//                 element: (
//                     <ProtectedRoute>
//                         <ELibrary/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/assessments",
//                 element: (
//                     <ProtectedRoute>
//                         <Assessments/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/scoresheet/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <Scoresheet/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/score-board",
//                 element: (
//                     <ProtectedRoute>
//                         <Scoreboard/>
//                     </ProtectedRoute>
//                 ),
//             },
//
//
//             {
//                 path: "/get-my-score/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <AssessmentScore view={false}/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/view-my-score/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <AssessmentScore view={true}/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/list-scripts",
//                 element: (
//                     <ProtectedRoute>
//                         <ScriptList/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/mark-scripts/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <MarkingScript/>
//                     </ProtectedRoute>
//                 ),
//             }, {
//                 path: "/notification-board-message/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <NotificationBoardDetailPage/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/notification-board-messages",
//                 element: (
//                     <ProtectedRoute>
//                         <NotificationBoardPage/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/fees",
//                 element: (
//                     <ProtectedRoute>
//                         <Fee/>
//                     </ProtectedRoute>
//                 ),
//             },
//
//             {
//                 path: "/fees-detail/:id",
//                 element: (
//                     <ProtectedRoute>
//                         <FeeDetail/>
//                     </ProtectedRoute>
//                 ),
//             },
//
//             {
//                 path: "/transaction-history",
//                 element: (
//                     <ProtectedRoute>
//                         <TransactionHistory/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/transfer-funds",
//                 element: (
//                     <ProtectedRoute>
//                         <TransferForm/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/withdraw-funds",
//                 element: (
//                     <ProtectedRoute>
//                         <WithdrawalForm/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/ai",
//                 element: (
//                     <ProtectedRoute>
//                         <AiView/>
//                     </ProtectedRoute>
//                 ),
//             },
//             {
//                 path: "/my-notifications",
//                 element: (
//                     <ProtectedRoute>
//                         <UserNotifications/>
//                     </ProtectedRoute>
//                 ),
//             },
//
//         ]
//     }
// ]);