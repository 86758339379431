import React from "react";
import TopMeunSection from "../../components/Blocks/TopMeunSection";
import {Context} from "../../context/Contexts";
import MultiChoiceOption from "./MultiChoiceOption";
import API from "../../api";
import {useParams} from "react-router-dom";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";
import SingleAnswerOption from "./SingleAnswerOption";
import Swal from "sweetalert2";

export default function QuestionContent({personinfo}) {
    if (personinfo.personType !== 'Student') {
        UnathorizedRedirect()
    }
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const {id} = useParams();
    const [questionObj, setQuestionObj] = React.useState([]);
    const initAnsObj = {answerValue: '', studentAnswer: ''};
    const [answerData, setAnswerData] = React.useState(initAnsObj);
    const [shouldClearEditor, setShouldClearEditor] = React.useState(false);
    const hosturl = process.env.REACT_APP_HOSST.replace("api/", "")


    const handleClearEditor = () => {
        setShouldClearEditor(true);
    };
    const handleGetNextQuestion = async () => {
        try {
            const res = await API.post("nextquestion", {id}, {headers});
            setQuestionObj(res.data);
            setAnswerData(initAnsObj);
            setTimer(res.data.duration || 0);
            handleClearEditor();
            setShouldClearEditor(false)
        } catch (e) {

        }
    };
    const handleProcessScore = async () => {
        Swal.fire({
            title: 'Sit tight',
            text: 'While we are calculating your score...',
            allowOutsideClick: false,
            onOpen: () => {
                Swal.showLoading();
            }
        });
        const req = async (e) => {
            try {
                setTimeout(async () => {
                    const res = await API.post('get_assessment_scores',
                        {id}, {headers})
                    if (res.data === 'saved') {
                        Swal.close();
                        window.location.replace(`/view-my-score/${id}`)
                    }
                }, 3000);

            } catch (e) {

            }
        }
        req()
    }
    const handelSaveAnswer = async () => {

        try {
            const res = await API.post('save_answer', {
                id: id,
                qId: questionObj.qId,
                currentQid: questionObj.currentQid,
                answerData
            }, {headers})
            res.data === 'saved' && handleGetNextQuestion()
        } catch (e) {

        }
    }


    React.useEffect(() => {
        handleGetNextQuestion(); // Call the function here
    }, [id]);

    const [timer, setTimer] = React.useState(questionObj.duration || 0); // Initialize timer with the duration if available, otherwise 0
    React.useEffect(() => {
        let countdown;

        const startTimer = () => {
            countdown = setInterval(() => {
                setTimer((prevTimer) => {
                    const newTimer = prevTimer - 1;
                    if (newTimer === 0) {
                        stopTimer();
                        handelSaveAnswer()

                        return 0;
                    }
                    return newTimer;
                });
            }, 1000);
        };
        const stopTimer = () => {
            clearInterval(countdown);
        };

        if (questionObj.duration) {
            // Only start the timer if questionObj.duration is available
            setTimer(questionObj.duration);
            startTimer();
        }

        return stopTimer;
    }, [questionObj]); // Include questionObj.duration as a dependency


    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };


    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="quiz-main-body">
                            <div className="quiz-container" id="quiz">
                                <div>
                                    {questionObj.length !== 0 ? (
                                        <React.Fragment>
                                            <p className="text-info text-center">Please note if you don't click on the
                                                Submit button your answer wil not be saved</p>
                                            <div className="quiz-header">
                                                <div className="d-flex">
                                                    <p className="timer">
                                                        <i className="fas fa-stopwatch"></i>: <b>{formatTime(timer)}</b>
                                                    </p>
                                                </div>
                                                <h2 id="question">
                                                    <div dangerouslySetInnerHTML={{__html: questionObj.question}}></div>
                                                </h2>
                                                {questionObj.questionImage !== null && (
                                                    <img
                                                        src={`${hosturl}${questionObj.questionImage}`}
                                                        alt="My Image"
                                                        className="img-fluid d-block mx-auto my-2"
                                                    />
                                                )}
                                                {questionObj.asstype === "multiple" && (
                                                    <MultiChoiceOption
                                                        answers={questionObj.answers}
                                                        answerData={answerData}
                                                        setAnswerData={setAnswerData}
                                                    />
                                                )}
                                                {questionObj.asstype === "single" && (
                                                    <SingleAnswerOption
                                                        questionOb={questionObj}
                                                        answerData={answerData}
                                                        setAnswerData={setAnswerData}
                                                        clearEditor={shouldClearEditor}
                                                    />
                                                )}


                                            </div>
                                            <button onClick={handelSaveAnswer} id="submit">Submit</button>
                                        </React.Fragment>

                                    ) : (
                                        <React.Fragment>
                                            <h2 className="text-center mb-5">No more questions</h2>
                                            {/*<a className="submit" href={`/get-my-score/${id}`} id="submit">Get my*/}
                                            {/*    score</a>*/}
                                            <button onClick={handleProcessScore} id="submit">Get my score</button>
                                        </React.Fragment>
                                    )}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
