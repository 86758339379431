import API from "../../api";
import Swal from "sweetalert2";
import React from "react";

const Assessments = (headers) => {
    const handleTakeAssessment = async (id) => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure you are ready for this?',
            text: 'Once you click YES you can no longer retake the assessment unless granted access by the school admin. Proceed with caution!',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (confirmResult.isConfirmed) {
            try {
                await API.post('take_assessment', {id}, {headers});
                window.location.replace('/take-assessment/' + id);
            } catch (e) {
                // Handle error
            }
        }
    };
    const buttons = [
        {
            label: 'TAKE IT',
            className: 'btn btn-sm btn-primary',
            onClick: (id) => () => handleTakeAssessment(id),
        },
    ];
    const formattedDate = (dateString) => {
        return (new Date(dateString).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })
        )
    }
    return [
        {
            cell: (row) => (
                // Render buttons within a cell
                <div>
                    {buttons.map((button, index) => (
                        !row.isTaken ? (
                            <button
                                className={button.className}
                                onClick={button.onClick(row.uuid)}
                                key={index}
                            >
                                {button.label} <i className="fas fa-arrow-right"></i>
                            </button>
                        ) :  <a className="btn btn-sm btn-primary" href={`/view-my-score/${row.uuid}`} >Result <i className="fas fa-file-code"></i></a>
                    ))}
                </div>

            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,

        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Assessment Type',
            selector: row => <span className="text-uppercase">{row.assessmentType}</span>,
            sortable: true,
        },
        {
            name: 'Attempted date',
            selector: row => row.attemptDate && formattedDate(row.attemptDate),
            sortable: true,
        },
        {
            name: 'Duration',
            selector: row => `${formattedDate(row.startDate)} - ${formattedDate(row.endDate)}`,
            sortable: true,
        }, {
            name: 'Taken',
            selector: row => (
                <span className={`badge ${row.isTaken ? 'bg-success' : 'bg-primary'}`}>
        {row.isTaken ? 'Attempted' : 'Not Attempted'}
      </span>
            ),
            sortable: true,
        },

    ];

}

export default Assessments;
