export const FeeTable = (hideBtn= null) => {
    const buttons = [
        {
            label: '',
            className: 'btn btn-sm btn-primary',
            onClick: () => {
                // Handle button 1 click event
            },
        },
        // {
        //     label: 'Text',
        //     className: 'btn btn-sm btn-warning',
        //     onClick: () => {
        //         // Handle button 2 click event
        //     },
        // },
        // Add more buttons as needed
    ];
    return [
        {
            cell: (row) => (
                // Render buttons within a cell
                hideBtn === null &&
                <div>
                   {buttons.map((button, index) => (
                        <a href={`fees-detail/${row.uuid}`} key={index} className={button.className}
                           onClick={button.onClick}>
                            {button.label} <i className="fas fa-arrow-right"></i>
                        </a>
                    ))}

                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,

        },
        {
            name: 'Invoice N°',
            selector: row => row.invoiceNo,
            sortable: true,
        },
        {
            name: 'Fee type',
            selector: row => <span className="text-uppercase">{row.type}</span>,
            sortable: true,
        },
        {
            name: 'Amount (#)',
            selector: row => row.amount.toLocaleString(),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                row.status ? <span className="badge bg-primary">CLEARED </span> : <span className="badge bg-danger">NOT CLEARED</span>
            ),
            sortable: true,
        },
        {
            name: 'Academic year',
            selector: row => `${row.semester} - ${row.year}`,
            sortable: true,
        },
    ];
}

export default FeeTable;