import './questiopage.scss';
import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import Content from "./QuestionContent";
import React from "react";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";

export default function QuestionPage () {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('Question page');
    UnathorizedRedirect(personinfo, 'Student')
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <Content personinfo={personinfo}/>
        </div>

    )
}
