import React from "react";
import {Context} from "../../context/Contexts";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";
import TopMeunSection from "../Blocks/TopMeunSection";

export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [scores, setScores] = React.useState([]);
    const [academicYears, setAcademicYears] = React.useState([]);
    const [totalGP, setTotalGP] = React.useState(0);
    const [totalUnit, setTotalUnit] = React.useState(0);
    const [totalGPA, setTotalGPA] = React.useState(0);
    const [carOver, setCarOver] = React.useState(0);

    React.useEffect(() => {
        const req = async () => {
            try {
                const res = await API.post("student_scoreboard", {}, {headers});
                setScores(res.data.scores);
                setAcademicYears(res.data.academicYears);
            } catch (e) {
                errorAlert("Oops", "There was an error in getting the data!");
            }
        };

        req();
    }, []);


    React.useEffect(() => {
        if (scores.length > 0) {
            let tGp = 0;
            let tu = 0;

            scores.forEach((score) => {
                const {total, units} = score;
                let scoreGrade;

                if (total >= 75 && total <= 100) {
                    scoreGrade = 5;
                } else if (total >= 65 && total <= 74) {
                    scoreGrade = 4;
                } else if (total >= 50 && total <= 64) {
                    scoreGrade = 3;
                } else if (total >= 40 && total <= 49) {
                    scoreGrade = 2;
                } else if (total >= 0 && total <= 39) {
                    scoreGrade = 0;
                    setCarOver(prevCarOver => prevCarOver + 1);
                }

                tGp += scoreGrade * units;
                tu += units;
            });

            setTotalUnit(parseFloat(tu.toFixed(2)));
            setTotalGP(parseFloat(tGp.toFixed(2)));
            setTotalGPA(parseFloat(tGp / tu).toFixed(2));
        }
    }, [scores]);




    // const getGrade = (total) => {
    //
    // }
    const scoreColumns = scores.map((s) => {
        let scoreGrades = 0;
        let grade = 0;
        const total = s.total;

        if (total >= 75 && total <= 100) {
            scoreGrades = 5;
            grade = 'A';
        } else if (total >= 65 && total <= 74) {
            scoreGrades = 4;
            grade = 'B';
        } else if (total >= 50 && total <= 64) {
            scoreGrades = 3;
            grade = 'C';
        } else if (total >= 40 && total <= 49) {
            scoreGrades = 2;
            grade = 'D';
        } else if (total >= 0 && total <= 39) {
            scoreGrades = 0;
            grade = 'F';
        }


        return (
            <div className="col-sm-12 col-lg-3" key={s.code}>
                <div className="card">
                    <div className="card-body text-center">
                        <div className="fw-bold border-bottom-2 my-3">
                            <h4>{s.total} <span title="Grade (Credit)">[{grade}]</span></h4>
                            <small className="mx-2">{s.code}</small>
                            <small className="mx-2">({s.units} Unit)</small>
                        </div>

                        <div className="fw-bold border-bottom-2 my-3">
                            GP: {scoreGrades}
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    const handleAcademicYearChange = async (e) => {
        let academicYearId= e.target.value;
        setTotalUnit(0);
        setTotalGP(0);
        setTotalGPA(0);
        setCarOver(0);
        try {
            const res = await API.post("student_scoreboard", {academicYearId}, {headers});
            setScores(res.data.scores);
        } catch (e) {
            errorAlert("Oops", "There was an error in getting the data!");
        }
    }
    const yearOptions = academicYears?.map((acYear) => (
        <option key={acYear.uuid} value={acYear.uuid}>
            {acYear.year} -> {acYear.semester}
        </option>
    ));
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="card">
                            <div className="card-body">
                                <h3>My score board</h3>
                                <form className="my-4">
                                    <div className="form-floating mb-3">
                                        <select onChange={handleAcademicYearChange}
                                                className="form-select"
                                                id="academicYearSelected"
                                                required="required"
                                                name="academicYearSelected">
                                            {yearOptions}
                                        </select>
                                        <label htmlFor="academicYearSelected">Select an academic year</label>
                                    </div>
                                </form>

                                <div className="row">
                                    {scoreColumns}
                                </div>

                                <div className="d-flex justify-content-between border-2 bg-success px-3 my-3">
                                    <div className="fw-bold border-bottom-2 my-3">
                                        TCU: {totalUnit}
                                    </div>
                                    <div className="fw-bold border-bottom-2 my-3"
                                         title="Total Grade Points">TGP: {totalGP}
                                    </div>
                                    <div className="fw-bold border-bottom-2 my-3"
                                         title="Grade Point Average">GPA: {totalGPA}
                                    </div>
                                    <div className="fw-bold border-bottom-2 my-3"
                                         title="Carry over">CO: {carOver}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Content;