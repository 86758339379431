export const WalletCard = (props) => {
    let previous = parseInt(props.balancePrev);
    let current = parseInt(props.balance);
    let diff = current - previous;
    let sum = current + previous / 2;
    let averg = 0;
    if (sum !== 0) {
        averg = Math.floor(diff / sum * 100);
    }
    let percentIcon = false;
    if (current > previous) {
        percentIcon = <b className="badge bg-success"><i className="fas fa-arrow-up"></i> {averg} %</b>
    } else {
        percentIcon = <b className="badge bg-danger"><i className="fas fa-arrow-down"></i> {averg} %</b>

    }
    return (
        <div className="col-sm-12 col mx-auto mt-3">
            <div className="card">
                <div className="card-body">
                    <small>{props.libelle}: &nbsp;
                        {percentIcon && percentIcon}
                    </small>
                    <h5>{parseInt(props.balance).toLocaleString()}</h5>
                </div>
            </div>

        </div>

    )
}
export default WalletCard;