import React from "react";
import TopMeunSection from "../Blocks/TopMeunSection";
import {Context} from "../../context/Contexts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PasswordChange from "./PasswordChange";
import TwoFactorAuth from "./TwoFactorAuth";
import UserProfile from "./UserProfile";


export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context)
    // const [personDetail, setPersonDetail] = React.useState({});

    // console.log(user);
    const headers = {
        Authorization: `Bearer ${user?.token}`,
    };


    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                                    type="button" role="tab" aria-controls="contact" aria-selected="false">Profile page
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="account-tab" data-bs-toggle="tab"
                                    data-bs-target="#account" type="button" role="tab" aria-controls="account"
                                    aria-selected="true">Account access
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="2fa-tab" data-bs-toggle="tab" data-bs-target="#2fa"
                                    type="button" role="tab" aria-controls="2fa" aria-selected="false">2FA
                            </button>
                        </li>

                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade" id="account" role="tabpanel"
                             aria-labelledby="account-tab">
                            <PasswordChange headers={headers}/>
                        </div>
                        <div className="tab-pane fade" id="2fa" role="tabpanel" aria-labelledby="2fa-tab">
                            <TwoFactorAuth headers={headers}/>
                        </div>
                        <div className="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab">...
                            <UserProfile headers={headers} personinfo={personinfo} token={user?.token}/>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
export default Content;