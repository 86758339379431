import React from "react";
import API from "../../api";
import {Context} from "../../context/Contexts";
import TopMeunSection from "../Blocks/TopMeunSection";
import {useParams} from "react-router-dom";
import ScoringFields from "./ScoringFields";
import errorAlert from "../../services/AlertS/error";
import successAlert from "../../services/AlertS/sucess";

export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const {id} = useParams();
    const [studentTakingCourse, setStudentTakingCourse] = React.useState([]);
    const [couserinfo, setCouserinfo] = React.useState([]);
    const [academicYears, setAcademicYears] = React.useState([]);
    React.useEffect(() => {
        const req = async (e) => {

            try {
                const res = await API.post("get_course_scoresheet", {id}, {headers})
                setStudentTakingCourse(res.data.list);
                setCouserinfo(res.data.course);
                setAcademicYears(res.data.academicYears);
            } catch (e) {

            }
        }
        req()
    }, []);

    const chanegAcademicYear = (acacdemicid) => {
        const req = async (e) => {

            try {
                const res = await API.post("get_course_scoresheet", {id, acacdemicid}, {headers})
                setStudentTakingCourse(res.data.list);
                setCouserinfo(res.data.course);
                setAcademicYears(res.data.academicYears);
            } catch (e) {

            }
        }
        req()
    }
    const handleSave = () => {
        const req = async (e) => {

            try {
                const res = await API.post("save_scoresheet", studentTakingCourse, {headers})
                successAlert('Nicely  done', 'Score-sheet saved')
            } catch (e) {
                errorAlert("Error occurred", e.response?.data);
            }
        }
        req()
    };
    const scorlide = studentTakingCourse.map((item, index) => {
        index += 1;
        return <ScoringFields data={item} index={index} studentTakingCourse={studentTakingCourse}
                              setStudentTakingCourse={setStudentTakingCourse}/>
    })
    const academicYearsSelect = academicYears.map((option) => {
        return <option value={option.uuid}>{option.year} {option.semester}</option>
    })

    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">

                                <h3 className="mb-4"><a className="dynmatxt" href="/courses"><i
                                    className="fas fa-arrow-left"></i></a> {couserinfo.title} [{couserinfo.code}]
                                    Score-sheet</h3>

                                <div className="d-flex justify-content-between">
                                    <div className="form-floating w-75">
                                        <select onChange={(e) => chanegAcademicYear(e.target.value)}
                                                className="form-select " id="floatingSelect"
                                                aria-label="Floating label select example">
                                            {academicYearsSelect}
                                        </select>

                                    </div>
                                    <button onClick={handleSave} className="btn btn-primary">Save <i
                                        className="fas fa-save"></i></button>
                                </div>
                                <div>
                                    {/*<p className="text-info fw-bold my-3">NB: Please w</p>*/}
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th className="fw-bold dynmatxt">#</th>
                                                <th className="fw-bold dynmatxt">Student</th>
                                                <th className="fw-bold dynmatxt">Test/20</th>
                                                <th className="fw-bold dynmatxt">Exam/60</th>
                                                <th className="fw-bold dynmatxt">Attendance/10</th>
                                                <th className="fw-bold dynmatxt">Assignment/10</th>
                                                <th className="fw-bold dynmatxt">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {scorlide ? scorlide : (
                                                <h3 className="text-center">No score-sheet for the selected academic
                                                    year</h3>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;