import {useParams} from "react-router-dom";
import '../../components/Regsitration/registration.scss';
import React from "react";
import setPageTitle from "../../services/TitleService";
import {useDispatch, useSelector} from "react-redux";
import {updateUserTheme} from "../../redux/userRedux";

export const RegistrationStatus = () => {
    const {status} = useParams();
    setPageTitle('Registration Form');
    const dispatchs = useDispatch();
    const theme = useSelector(state => state.user.userTheme);
    if (theme) {
        document.body.classList.add('dark-theme');
    } else {
        document.body.classList.remove('dark-theme');
    }
    const handleThemeToggle = () => {
        dispatchs(updateUserTheme(!theme))
    };
    return (
        <div className="rpcp111">
            <div className="h47o">
                <div className="a8x12dwz">
                    <div className="formContainer">
                        <div className="css-jz6d9T">
                            <img src=""
                                 alt="Portal_logo"
                                 aria-label="Portal logo"
                                 className="css-134k5z9"/>
                            <div className="d-flex justify-content-between my-2">
                                <div>
                                    <small
                                        className="me-3 switchTheme cursor"
                                        onClick={handleThemeToggle}>
                                        Toggle dark-mode <i className="las la-sun la-1x"></i>

                                    </small>
                                </div>
                                <div>
                                    <a href="/login"
                                       className="me-3 text-decoration-none primary-color fw-bold small">
                                        Go to login form <i className="las la-external-link-alt la-1x"></i>
                                    </a>
                                </div>
                            </div>
                            <div>
                                {status === 'successful' && (
                                    <>
                                        <h3 className="text-center dynmatxt my-3">Your application was a <br/>
                                            <b className="text-success">Success</b>
                                        </h3>
                                        <img className="d-block mx-auto mb-5" src="/dist/img/exciting_news.svg" alt="SUCCESS IMAGE" style={{ height: '300px' }}/>
                                        <p className="dynmatxt">The school authorities will thoroughly evaluate your application,
                                            and upon approval, you will be granted an official admission letter within 7
                                            business days. The admission letter will be sent to you via email or
                                            WhatsApp, ensuring prompt delivery and convenience for your reference. </p>

                                    </>
                                )}


                                {status === 'existing' && (
                                    <>
                                        <h3 className="text-center dynmatxt my-3 dynmatxt">Your application was not <br/>
                                            <b className="text-danger">Not Success</b>
                                        </h3>
                                        <p className="dynmatxt">We regret to inform you that there is already a student registered with the
                                            exact details you have provided. Kindly review your information and attempt
                                            the registration process once more. If this issue persists, we recommend
                                            reaching out to our dedicated school support team for further assistance and
                                            guidance. </p>
                                    </>
                                )}

                                {status !== 'successful' && status !== 'existing' && (
                                    <>
                                        <h3 className="text-center dynmatxt my-3">Your application was <br/>
                                            <b className="text-danger">Not Successful</b>
                                        </h3>
                                        <img className="d-block mx-auto mb-5" src="/dist/img/fig_bugs_.svg" alt="ERROR IMAGE" style={{ height: '300px' }}/>
                                        <p className="dynmatxt">
                                            Kindly try again and ensure that you fill all the required fil
                                        </p>
                                    </>
                                )}


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationStatus;