import LecturerMenu from "./LecturerMenu";
import AgentMenu from "./AgentMenu";
import StudentMenu from "./StudentMenu";

export const MenuSection = ({personinfo}) => {

    return (
        <>
            <div className="menu-section">
                {personinfo?.personType === 'Agent' && <AgentMenu/>}
                {personinfo?.personType === 'Lecturer' && <LecturerMenu/>}
                {personinfo?.personType === 'Student' && <StudentMenu/>}
                <div className="support-btn p-2">

                    <p className="text-white-50 text-center"><a target="_blank" href="#">System updates</a></p>
                    <p className="text-white fw-bold text-center mb-1"><small>Portal V 2.0</small></p>
                </div>
            </div>
        </>
    )
}
export default MenuSection
