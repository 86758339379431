export default function UnauthorizedRedirect(personinfo, persontype) {
    const isPersonTypeArray = Array.isArray(persontype);
    if (isPersonTypeArray) {
        if (persontype.includes('All') || !persontype.includes(personinfo.personType)) {
            window.location.replace('/un-authorized');
            return;
        }
    } else {
        if (persontype !== 'All' && personinfo.personType !== persontype) {
            window.location.replace('/un-authorized');
            return;
        }
    }
}
