import React, {useEffect} from "react";
import API from "../../api";
import warningAlert from "../../services/AlertS/warning";

export const FIleUpload = ({formStep, personfileTypes, setUploadFiles, setValue}) => {


    const handleFileUpload = (filetype, event) => {
        const file = event.target.files[0];
        let maxSizeFormatted = (String(filetype.maxSize).length > 6) ? String(filetype.maxSize).slice(0, 1) + " MB" :
            String(filetype.maxSize).slice(0, 3) + " KB";
        if (file.size > Number(filetype.maxSize)) {
            warningAlert("File upload error", "Max file size upload is " + maxSizeFormatted);
            event.target.value = ""
            return false;
        }

        const fileData = new FormData();
        fileData.append("file", file);

        setUploadFiles((prevState) => ({
            ...prevState,
            [filetype.uuid]: fileData.get("file"),
            codereference:filetype.codereference
        }));
    };

    const filetypeOutPut = personfileTypes.map((filetype, index) => {
        let fileformat;
        if (filetype.fileFormat === 'images_only') fileformat = '.png,.jpeg,.jpg,.webp';
        if (filetype.fileFormat === 'docs_only') fileformat = '.pdf,.pptx,.doc,.docx';
        if (filetype.fileFormat === 'docs_images_only') fileformat = '.pdf,.pptx,.doc,.docx,.png,.jpeg,.jpg,.webp';

        let maxSizeFormatted = (String(filetype.maxSize).length > 6) ? String(filetype.maxSize).slice(0, 1) + " MB" :
            String(filetype.maxSize).slice(0, 3) + " KB";
        return (
            <div className="col-sm-12 col-md-6" key={filetype.uuid}>
                <div className="mb-3">
                    <label htmlFor={filetype.uuid} className="form-label">
                        {filetype.libelle}
                        {filetype.isRequired && (<span className="text-danger small-text">REQUIRED</span>)} <small className="text-primary">{"Max size: " + maxSizeFormatted}</small>
                    </label>
                    <input
                        onChange={(event) => handleFileUpload(filetype, event)}
                        className="form-control"
                        type="file"
                        required={filetype.isRequired && "required"}
                        id={filetype.uuid}
                        accept={fileformat}
                    />
                </div>
            </div>
        )
    })

    return (
        <div className={formStep !== 3 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">UPLOAD FILES</h3>
            <p className="small text-secondary text-center">Kindly upload your files to finalize your admission</p>
            <p className="text-end dynmatxt">STEP: <small className="fw-bold">{formStep}/4</small></p>
            <div className="row">
                {filetypeOutPut}
            </div>
        </div>
    )
}
export default FIleUpload;