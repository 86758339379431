import React from "react";
import API from '../../api'
import {Context} from "../../context/Contexts";
import './login.scss'
import {leftSidetoggled, updateuserinfo, updateUserTheme} from "../../redux/userRedux";
import errorAlert from "../../services/AlertS/error";
import {useDispatch, useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";

export const LoginForm = () => {
    setPageTitle('Login Page');
    const {dispatch} = React.useContext(Context);
    const dispatchs = useDispatch();
    const [formData, setFormData] = React.useState({
        username: "", password: ""
    })
    const [isLoading, setLoading] = React.useState(false);
    const theme = useSelector(state => state.user.userTheme);
    if (theme) {
        document.body.classList.add('dark-theme');
    } else {
        document.body.classList.remove('dark-theme');
    }
    const handleThemeToggle = () => {
        dispatchs(updateUserTheme(!theme))
    };

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevState => {
            return {
                ...prevState, [name]: type === "checkbox" ? checked : value
            }
        })
    }

    // React.useEffect(() => {
    //
    // }, [])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormData(prevState => {
            return {
                ...prevState, username: "", password: ""
            }
        })
        setLoading(true);
        try {
            dispatchs(updateuserinfo(null))
            const res = await API.post('login_verify', {...formData})
            dispatch({type: "LOGIN_SUCCESS", payload: res.data})
            const headers = {
                Authorization: `Bearer ${res.data.token}`,
            };
            if (res.status === 200) {
                // Set user info in Redux
                const response = await API.post('getUserInfo', {}, {headers})
                dispatch(updateuserinfo(response.data))
                dispatch(leftSidetoggled(false))
                window.location.replace('/dashboard')
            }

        } catch (error) {
            // console.log(error.response.data);
            dispatch({type: "LOGIN_FAILURE"})
            setLoading(false);
            errorAlert("Login Error", error.response.data.message)
        }

    }


    return (
        <div className="rpcp111">
            <div className="h47o">
                <div className="a8x12z">
                    <div className="loginContainer">
                        <div>
                            <div className="css-45jibn">
                                <div className="css-18wvllj">
                                    <div className="css-r721a9">
                                    <span className="css-9ku9xq">
                                    <svg role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="100%"
                                         height="100%" viewBox="0 0 20 20" aria-labelledby="NewIcon-1">
                                        <path fill="currentColor" fillRule="evenodd"
                                              d="M6.991 7.507c.003-.679 1.021-.675 1.019.004-.012 2.956 1.388 4.41 4.492 4.48.673.016.66 1.021-.013 1.019-2.898-.011-4.327 1.446-4.48 4.506-.033.658-1.01.639-1.018-.02-.03-3.027-1.382-4.49-4.481-4.486-.675 0-.682-1.009-.008-1.019 3.02-.042 4.478-1.452 4.49-4.484zm.505 2.757l-.115.242c-.459.9-1.166 1.558-2.115 1.976l.176.08c.973.465 1.664 1.211 2.083 2.22l.02.05.088-.192c.464-.973 1.173-1.685 2.123-2.124l.039-.018-.118-.05c-.963-.435-1.667-1.117-2.113-2.034l-.068-.15zm10.357-8.12c.174.17.194.434.058.625l-.058.068-1.954 1.905 1.954 1.908a.482.482 0 010 .694.512.512 0 01-.641.056l-.07-.056-1.954-1.908-1.954 1.908a.511.511 0 01-.71 0 .482.482 0 01-.058-.626l.058-.068 1.954-1.908-1.954-1.905a.482.482 0 010-.693.512.512 0 01.64-.057l.07.057 1.954 1.905 1.954-1.905a.511.511 0 01.71 0z"></path>
                                    </svg>
                                    </span>
                                    </div>
                                    <div className="css-lwy70"><h3
                                        className="css-30z9dv">Welcome to the new
                                        Portal Log in experience!</h3></div>
                                </div>
                            </div>
                            <div className="css-jz6rhf">
                                <div className="login-form">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <small
                                                className="me-3 switchTheme cursor"
                                                onClick={handleThemeToggle}>
                                                Toggle dark-mode <i className="las la-sun la-1x"></i>

                                            </small>
                                        </div>
                                        <div>
                                            <a href="/application-form"
                                               className="me-3 text-decoration-none primary-color fw-bold small">
                                                Become a student <i className="las la-external-link-alt la-1x"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="css-1crlkb6">
                                        <img src=""
                                             alt="Portal_logo"
                                             aria-label="Portal logo"
                                             className="css-134k5z9"/>
                                        <h2 className="css-871z51">Log in</h2>
                                        <div className="css-1w53erm">
                                            <form onSubmit={handleSubmit}>
                                                <div className="css-1brb0pv">
                                                    <div className="css-qpdxik">
                                                        <div className="css-16do72l">
                                                            <div className="form-floating mb-3">
                                                                <input type="text" className="form-control"
                                                                       id="floatingInputUsername" name="username"
                                                                       value={formData.username} onChange={handleChange}
                                                                       placeholder="Enter username"
                                                                />
                                                                <label htmlFor="floatingInputUsername">Enter
                                                                    username</label>
                                                            </div>
                                                            <div className="form-floating mb-3">
                                                                <input type="password" className="form-control"
                                                                       id="floatingInputPassword" name="password"
                                                                       value={formData.password} onChange={handleChange}
                                                                       placeholder="Password"/>
                                                                <label htmlFor="floatingInputPassword">Password</label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <button type="submit" disabled={isLoading}
                                                            className="btn btn-primary w-100 mb-4 btn-lg cs84jfbtn">Continue
                                                        {isLoading &&
                                                            <i className="fas fa-circle-notch fa-spin"></i>}
                                                    </button>

                                                    <div className="css-roynbj">
                                                        <div className="css-1gf30db">
                                                            <div className="css-roynbj">
                                                                <div className="css-tcah8o">Are you having any diffculty
                                                                    accessing
                                                                    <div className="dropdown">
                                                                        <span id="dropdownMenuButton1"
                                                                              data-bs-toggle="dropdown"
                                                                              aria-expanded="false"
                                                                              className="text-danger"> your account? <i
                                                                            className="fas fa-chevron-circle-down"></i></span>
                                                                        <ul className="dropdown-menu"
                                                                            aria-labelledby="dropdownMenuButton1">
                                                                            <li><a className="dropdown-item"
                                                                                   href="#">Forgot username !</a></li>
                                                                            <li><a className="dropdown-item" href="#">Forgot
                                                                                password !</a></li>
                                                                        </ul>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;