import React, { useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const SingleAnswerOption = ({ questionObj, answerData, setAnswerData, clearEditor }) => {
    const editorRef = useRef(null);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setAnswerData((prev) => ({
            ...prev,
            studentAnswer: data
        }));
    };

    useEffect(() => {
        if (clearEditor && editorRef.current) {
            editorRef.current.editorInstance.setData('');
        }
    }, [clearEditor]);

    return (
        <>
            <CKEditor editor={ClassicEditor} onChange={handleEditorChange} onReady={(editor) => {
                editorRef.current = editor;
            }} />
        </>
    );
};

export default SingleAnswerOption;
