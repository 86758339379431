import React from "react";
import API from "../../../api";
import {Button, Modal} from "react-bootstrap";
import DataTable from "react-data-table-component";
import FeeTable from "../Fees/FeeTable";

export const FeeModal = ({
							 headers,
							 modalShow,
							 setFormData,
							 handleToggleModal,
							 apptheme,
							 ChangeWalletState,
							 setSelectedFee,
						 }) => {
	const [Fees, setFees] = React.useState([]);
	const [pending, setPending] = React.useState(true);
	const [searchText, setSearchText] = React.useState("");

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			const req = async (e) => {
				try {
					const res = await API.post("fees_transactions", {}, { headers });
					setFees(res.data);
					setPending(false);
				} catch (e) {
					// Handle error
				}
			};
			req();
		}, 1500);
		return () => clearTimeout(timeout);
	}, [ChangeWalletState]);

	const handleSearch = (event) => {
		setSearchText(event.target.value);
	};

	const filteredData = Fees.filter(
		(row) =>
			row.type.toLowerCase().includes(searchText.toLowerCase()) ||
			row.invoiceNo.toLowerCase().includes(searchText.toLowerCase())
	);

	const ExpandedComponent = ({ data }) => (
		<div>
			<p>
				Fee type: <b>{data.type}</b>
			</p>
			<p>
				Amount: <b>{data.amount.toLocaleString()}</b>
			</p>
			<p>
				Status: <b>{data.status ? "CLEARED" : "NOT CLEARED"}</b>
			</p>
			<p>
				Academic year: <b>{data.semester} - {data.year}</b>
			</p>
		</div>
	);

	const handleRowSelected = (rows) => {
		if (rows.selectedRows.length > 0) {
			handleToggleModal()
			setFormData((prevState) => {
				return {
					...prevState,
					selectFee: rows.selectedRows[0].uuid,
				};
			});setSelectedFee((prevState) => {
				return {
					...prevState,
					uuid: rows.selectedRows[0].uuid,
					feetype: rows.selectedRows[0].type
				};
			});
		}
	};

	return (
		<Modal show={modalShow} onHide={handleToggleModal} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>
					<div className="d-flex w-100">
						<span>My fees</span>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<input
					type="search"
					className="form-control mb-3"
					value={searchText}
					onChange={handleSearch}
					placeholder="Search by title or code..."
				/>
				<DataTable
					responsive
					striped
					onSelectedRowsChange={handleRowSelected}
					theme={apptheme ? "dark" : undefined}
					direction="auto"
					subHeaderAlign="right"
					subHeaderWrap
					selectableRows
					expandableRows
					progressPending={pending}
					expandableRowsComponent={ExpandedComponent}
					columns={FeeTable(true)}
					data={filteredData}
					pagination
					persistTableHead
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleToggleModal}>
					Close
				</Button>
				{/* Add additional buttons or actions as needed */}
			</Modal.Footer>
		</Modal>
	);
};

export default FeeModal;
