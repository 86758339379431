export default [
    {
        id: 0,
        name: 'Updates',
        imagelink: "/dist/img/whats-new.png",
        link: "#",
    },
    // {
    //     id: 1,
    //     name: 'Score sheets',
    //     imagelink: "/dist/img/students.png",
    //     link: "/courses",
    // },
    // {
    //     id: 2,
    //     name: 'AI',
    //     imagelink: "/dist/img/robot.png",
    //     link: "/ai",
    // },
];