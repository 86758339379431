import React from "react";
import {Context} from "../../context/Contexts";
import API from "../../api";
import TopMeunSection from "../../components/Blocks/TopMeunSection";
import DataTable from "react-data-table-component";
import {useSelector} from "react-redux";
import {MarkScriptTable} from "./MarkScriptTable";

export const ScriptListContent = ({personinfo}) => {
    const [testScripts, setTestScripts] = React.useState([]);
    const [examScripts, setExamScripts] = React.useState([]);
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };

    React.useEffect(() => {
        const req = async (e) => {
            try {

                const res = await API.post('view_scripts',
                    {}, {headers})
                setTestScripts(res.data.test)
                setExamScripts(res.data.exam)
            } catch (e) {

            }
        }
        req()
    }, []);
    const apptheme = useSelector(state => state.user.userTheme);

    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <h3>Script list</h3>
                                <div className="mt-4">
                                    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="exam-tab" data-bs-toggle="tab"
                                                    data-bs-target="#exam-tab-pane" type="button" role="tab"
                                                    aria-controls="exam-tab-pane"
                                                    aria-selected="true">Exam [{examScripts ? examScripts.length : 0}]
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="tests-tab" data-bs-toggle="tab"
                                                    data-bs-target="#tests-tab-pane" type="button" role="tab"
                                                    aria-controls="tests-tab-pane"
                                                    aria-selected="false">Test [{testScripts ? testScripts.length : 0}]
                                            </button>
                                        </li>

                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="exam-tab-pane" role="tabpanel"
                                             aria-labelledby="exam-tab"
                                             tabIndex="0">
                                            <DataTable
                                                responsive
                                                striped
                                                theme={apptheme ? "dark" : undefined} // Fixed theme assignment
                                                direction="auto"
                                                subHeaderAlign="right"
                                                subHeaderWrap
                                                columns={MarkScriptTable(headers)}
                                                data={examScripts}
                                                pagination
                                            />
                                        </div>
                                        <div className="tab-pane fade" id="tests-tab-pane" role="tabpanel"
                                             aria-labelledby="tests-tab"
                                             tabIndex="0">
                                            <DataTable
                                                responsive
                                                striped
                                                theme={apptheme ? "dark" : undefined} // Fixed theme assignment
                                                direction="auto"
                                                subHeaderAlign="right"
                                                subHeaderWrap
                                                columns={MarkScriptTable(headers)}
                                                data={testScripts}
                                                pagination
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ScriptListContent;