import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import React from "react";
import TransferFormContent from "../../components/Fee&Transaction/Transaction/TransferFormContent"

export const TransferForm = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('Transfer Funds');
	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<TransferFormContent personinfo={personinfo}/>
		</div>
	)
}
export default TransferForm