import React from "react";
import {Context} from "../../context/Contexts";
import TopMeunSection from "../Blocks/TopMeunSection";
import AssetItem from "./AssetItem";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";
import warningAlert from "../../services/AlertS/warning";
import CreateAssetModal from "./CreateAssetModal";
import {useSelector} from "react-redux";

export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [searchText, setSearchText] = React.useState("");
    const [assets, setAssets] = React.useState([]);
    const [assetsTemp, setAssetsTemp] = React.useState([]);
    const [assetsCategory, setAssetsCategory] = React.useState([]);
    const [lecturers, setLecturers] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(null);
    const [selectedLecturer, setSelectedLecturer] = React.useState(null);
    const [pageCount, setPageCount] = React.useState(2);

    const [canLoadMore, setCanLoadMore] = React.useState(true);

    const fetchData = async () => {
        try {
            const res = await API.post("library_assets", {}, {headers});
            if (res.data.assets.length > 0) {
                setAssets(res.data.assets);
                setAssetsTemp(res.data.assets);

            } else {
                warningAlert("Oop!", "There are no library assets to load!");
            }
            setAssetsCategory(res.data.categories);
        } catch (e) {
            errorAlert("Error occurred", "An error occurred while getting data!");
        }
    };
    const handleLoadMore = React.useCallback(() => {
        if (canLoadMore) {
            const req = async () => {
                try {
                    const res = await API.post(
                        "library_assets",
                        {page: (pageCount)},
                        {headers}
                    );
                    if (res.data.assets.length > 0) {
                        setPageCount((prevState) => prevState + 1);
                        setAssets((prevState) => [...prevState, ...res.data.assets]);
                        setAssetsTemp((prevState) => [...prevState, ...res.data.assets]);

                    } else {
                        warningAlert("Oop!", "There are no more library assets to load!");
                    }

                } catch (e) {
                    warningAlert("Oop!", "There are no more library assets to load!");
                    setCanLoadMore(false);
                }
            };
            req();
        }
    }, [canLoadMore, pageCount]);

    const handleCategorySelect = React.useCallback((categoryId) => {
        setSelectedCategory(categoryId);
    }, [selectedCategory]);


    React.useEffect(() => {

        fetchData();

        const fetchLecturers = async () => {
            try {
                const response = await API.post("get_lecturers", {}, {headers});
                setLecturers(response.data);
            } catch (e) {
                errorAlert("Error occurred", "An error occurred while getting data!");
            }
        };
        fetchLecturers();
    }, []);

    let filteredAssets = assets;

    const handleAuthorSelect = React.useCallback((authorId) => {
        setSelectedLecturer(authorId);
    }, [selectedLecturer]);

    if (selectedCategory) {
        filteredAssets = filteredAssets.filter(
            (asset) => asset.categoryId === selectedCategory
        );
    }
    if (selectedLecturer) {
        filteredAssets = filteredAssets.filter(
            (asset) => asset.authorId === selectedLecturer
        );
    }

    if (searchText) {
        filteredAssets = filteredAssets.filter(
            (asset) =>
                asset.title.toLowerCase().includes(searchText.toLowerCase()) ||
                asset.category.toLowerCase().includes(searchText.toLowerCase())
        );
    }

    const baseURL = API.defaults.baseURL.slice(0, -4);

    const handleToggleModalWrapper = (asset) => {
        handleToggleModal(asset);
    };

    let assetsElement = filteredAssets.map((box, index) => {
        return (
            <AssetItem
                key={index}
                baseurl={baseURL}
                asset={box}
                personinfo={personinfo}
                handleToggleModal={handleToggleModalWrapper}
            />
        );
    });
    const handleSearchInput = (event) => {
        setSearchText(event.target.value)

    }
    const handleClearSearch = () => {
        setAssets([])
        setAssets((prevState) => [...prevState, ...assetsTemp]);
        setSearchText('')
    }
    const handleSearch = (event) => {
        const req = async () => {
            try {
                if (searchText.length >= 2) {
                    const res = await API.post(
                        "search_library_assets",
                        {keyword: (searchText)},
                        {headers}
                    );

                    if (res.data.length > 0) {
                        setAssets(res.data)
                    }
                }

            } catch (e) {
                warningAlert("Oop!", "AN error occurred while fetching library data!");
                setCanLoadMore(false);
            }
        };
        req();
    };
    let categoryElements = assetsCategory.map((box) => {
        return (
            <li key={box.id} onClick={() => handleCategorySelect(box.id)}>
                {box.libelle}
            </li>
        );
    });

    let lecturerElements = lecturers.map((box) => {
        return (
            <li key={box.uuid}>
        <span
            className="badge bg-primary"
            onClick={() => handleAuthorSelect(box.uuid)}
        >
          {box.firstname} {box.lastname}
        </span>
            </li>
        );
    });

    const [modalShow, setModalShow] = React.useState(false);

    const handleToggleModal = (option = null) => {
        if (option !== null) {
            const edit = {
                title: option.title,
                category: option.categoryId,
                file: "",
                note: option.note,
                uuid: option.uuid,
            };
            setFormData(edit);
        }
        setModalShow((prevState) => !prevState);
    };

    const handleAssetCreated = () => {
        // Refresh assets after successful creation
        const fetchAssets = async () => {
            try {
                const { data } = await API.post("library_assets", {}, { headers });
                setAssets(data.assets);
            }  catch (e) {
                errorAlert("Error occurred", "An error occurred while getting data!");
            }
        };
        fetchAssets();
        // Close the modal
        handleToggleModal();
    };

    const initForm = {
        title: "",
        category: "",
        file: "",
        note: "",
        uuid: "",
    };

    const [formData, setFormData] = React.useState(initForm);

    const apptheme = useSelector((state) => state.user.userTheme);

    return (
        <div className="content">
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row e-library">
                    <div className="col-sm-12 col-md-3">
                        <div className="card filter">
                            {personinfo.personType === "Lecturer" && (
                                <>
                                    <button
                                        onClick={handleToggleModal}
                                        className="btn btn-primary btn-sm"
                                    >
                                        Create an Asset
                                    </button>
                                    <CreateAssetModal
                                        token={user.token}
                                        initForm={initForm}
                                        formData={formData}
                                        setFormData={setFormData}
                                        assetsCategory={assetsCategory}
                                        modalShow={modalShow}
                                        handleToggleModal={handleToggleModal}
                                        apptheme={apptheme}
                                        onAssetCreated={handleAssetCreated}
                                    />
                                </>
                            )}

                            <div className="card-body categories">
                                <h4>Filter by Categories</h4>
                                <ul>{categoryElements}</ul>
                            </div>
                            <div className="card-body categories">
                                <h4>Filter by Author</h4>
                                <ul className="list-style-no">{lecturerElements}</ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        {/*<input*/}
                        {/*    type="search"*/}
                        {/*    className="form-control mb-3"*/}
                        {/*    value={searchText}*/}
                        {/*    onChange={handleSearch}*/}
                        {/*    */}
                        {/*/>*/}
                        <div className="input-group mb-3">
                            <input onChange={handleSearchInput} type="text" className="form-control"
                                   placeholder="Search by title or code..." aria-label="Username"
                                   aria-describedby="basic-addon1" value={searchText}/>
                            <button onClick={handleSearch} className="input-group-text btn btn-outline-success"
                                    title="Search for asset" id="basic-addon1"><i className="fas fa-search"></i>
                            </button>
                            <button onClick={handleClearSearch} className="input-group-text btn btn-outline-danger"
                                    title="Clear search input fied" id="basic-addon1"><i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h4>Library Assets</h4>
                                <div className="row">{assetsElement}</div>
                            </div>
                            <button
                                onClick={handleLoadMore}
                                className="btn my-2 mx-3 btn-outline-success btn-sm"
                            >
                                Load more
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;

