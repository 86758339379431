import TopMeunSection from "../Blocks/TopMeunSection";
import React, {useEffect, useState} from "react";
import {Context} from "../../context/Contexts";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";

export const NBDetailContent = ({id, personinfo}) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    useEffect(() => {
        const req = async (e) => {

            try {
                const res = await API.post('get_school_messageinfo', {uuid: id}, {headers})
                setNotifications(res.data);
                setLoading(false);
            } catch (e) {
                errorAlert('Oop', 'Error while getting data!')
            }
        }
        req()

    }, [0]);
    const formattedDate = (dateString) => {
        return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };
    const sanitizeHTML = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                        <div className="card">
                            <div className="card-body">

                                {loading ? (
                                    <h1 className="text-center">
                                        <i className="fas fa-spinner fa-spin-pulse"></i><br/>
                                        Loading
                                    </h1>
                                ) : (
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h4 className="mb-1"><a className="dynmatxt" href="/notification-board-messages"><i className="fas fa-arrow-left"></i></a></h4>
                                                <span><b className="badge bg-secondary">#{notifications.category}</b></span>
                                            </div>
                                            <span className="text-end">Created on: <br/><b>{formattedDate(notifications.created)}</b></span>
                                        </div>
                                        <h3 className="mt-4 text-center">{notifications.title}</h3>
                                        <div className="my-3">{sanitizeHTML(notifications.description)}</div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NBDetailContent;