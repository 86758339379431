import React from "react";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";
import successAlert from "../../services/AlertS/sucess";

export const PasswordChange = ({headers}) => {
    const [formData, setFormData] = React.useState({
        oldpassword: "", password: "", confpassword: ""
    })

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevState => {
            return {
                ...prevState, [name]: type === "checkbox" ? checked : value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formData.password === formData.confpassword) {
                const res = await API.post('user_reset_password', {...formData},{headers})
                if(res.data==='ok')successAlert('Password reset','You have successfully updated you password')

            }
        } catch (error) {
            errorAlert('Password reset error','An error occurred while updating you password')
        }
    }
    return (
        <form className="card" onSubmit={handleSubmit}>
            <div className="card-body">
                <div className="form-floating mb-4">
                    <input required={true} type="password" name="oldpassword" className="form-control" placeholder="&nbsp;" id="floatingPassword"
                           value={formData.oldpassword} onChange={handleChange}/>
                    <label htmlFor="floatingPassword">Current Password</label>
                </div>
                <div className="form-floating mb-4">
                    <input required={true} type="password" name="password" className="form-control" placeholder="&nbsp;" id="floatingNewPassword"
                           value={formData.password} onChange={handleChange}/>
                    <label htmlFor="floatingNewPassword">New Password</label>
                </div>
                <div className="form-floating mb-4">
                    <input required={true} type="password" name="confpassword" placeholder="&nbsp;" className={`form-control ${formData.password !== formData.confpassword ? 'is-invalid' : ''}`}
                           id="floatingConfPassword"
                           value={formData.confpassword} onChange={handleChange}/>
                    <label htmlFor="floatingConfPassword">Confirm Password</label>
                    {formData.password !== formData.confpassword ? <div id="emailHelp" className="form-text text-danger">Passwords do not match!</div> : null}

                </div>
                <button className="btn btn-success">Submit</button>
            </div>
        </form>
    )
}
export default PasswordChange;