import React from "react";
import API from "../../api";

export const TransactionTable = ({headers}) => {
    const [transactions, setTransactions] = React.useState([]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
                    const req = async (e) => {

                        try {
                            const res = await API.post('transaction_history', {maxnumber: 3}, {headers})
                            setTransactions(res.data);
                        } catch (e) {

                        }
                    }
                    req()
                }
                ,
                1500
            )
        ;
        return () => clearTimeout(timeout);
    }, []);
    const formattedDate = (dateString) => {
        return (new Date(dateString).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })
        )
    }
    const tableContent = () => {
        if (transactions.length === 0) {
            return (
                <tr>
                    <td className="text-center" colSpan="5">No Transaction made yet</td>
                </tr>
            );
        } else {
            return transactions.map((t) => (
                <tr key={t.id}>
                    <td>
                        <button className="btn btn-primary"><i className="fas fa-arrow-right"></i></button>
                    </td>
                    <td className="" dangerouslySetInnerHTML={{ __html: t.details }}></td>
                    <td className="text-center">{t.amount}</td>
                    <td className="text-center text-uppercase">{t.transtype}</td>
                    <td className="text-end">{formattedDate(t.created)}</td>
                </tr>
            ));

        }
    };

    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th><i className="las la-ellipsis-v"></i></th>
                <th>Transaction detail</th>
                <th className="text-center">Amount</th>
                <th className="text-center">Transaction type</th>
                <th className="text-end">Issued on</th>
            </tr>
            </thead>
            <tbody>
            {tableContent()}
            </tbody>
        </table>
    );

}
export default TransactionTable;