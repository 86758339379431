export const AffiliateTable = () => {
	const formattedDate = (dateString) => {
		return (new Date(dateString).toLocaleDateString("en-US", {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
			})
		)
	}
	return [

		{
			name: 'Students',
			selector: row => `${row.lastname}  ${row.firstname}`,
			sortable: true,
		},
		{
			name: 'Is Admitted',
			selector: row => (
				row.admitted ? <span className="badge bg-danger">Yes </span> : <span className="badge bg-primary">No</span>
			),
			sortable: true,
		},
		{
			name: 'Has paid',
			selector: row => (
				row.paid ?<span className="badge bg-primary">Yes</span> :  <span className="badge bg-warning">No </span>
			),
			sortable: true,
		},
		{
			name: 'Join on',
			selector: row => formattedDate(row.created),
			sortable: true,
		}


	];
}
export default AffiliateTable;
