export const AgentMenu = () => {
    return (
            <ul>
                <li><a href="/dashboard">Dashboard</a></li>
                <li><a href="/my-affilitates">Affiliation</a></li>
                <li className="navi-menu">
                    <a>Transactions </a>
                    <div className="submenu">
                        <p className="menu-title">Transactions</p>
                        <ul>
                            <li><a href="/transfer-funds">Transfer</a></li>
                            <li><a href="/withdraw-funds">Withdraw</a></li>
                            <li><a href="/transaction-history">Transactions history</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
    )
}
export default AgentMenu;