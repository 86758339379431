import React, {useEffect} from 'react';
import API from "../api";

const FaviconService = () => {

    const [schoolLogo, setSchoolLogo] = React.useState(false);
    const baseURL = API.defaults.baseURL.slice(0, -4);
    useEffect(() => {
        const req = async (e) => {

            try {
                const res = await API.post('get_school_logo', {}, {})
                setSchoolLogo(res.data)
            } catch (e) {

            }
        }
        req()

        const faviconLink = document.querySelector("link[rel~='icon']");
        const appleTouchIconLink = document.querySelector("link[rel~='apple-touch-icon']");
        const portalLogoLink = document.querySelector("img[alt~='Portal_logo']");
        if (schoolLogo && faviconLink) {
            faviconLink.href = baseURL+schoolLogo;
        }
        if (schoolLogo && appleTouchIconLink) {
            appleTouchIconLink.href = baseURL+schoolLogo;
        }
        if (schoolLogo && portalLogoLink) {
            portalLogoLink.src = baseURL+schoolLogo;
        }
    }, [schoolLogo,baseURL]);

    return null;
};
export default FaviconService;