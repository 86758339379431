import React from "react";
import {Context} from "../../context/Contexts";
import TopMeunSection from "../Blocks/TopMeunSection";
import DataTable from "react-data-table-component";
import AffiliateTable from "./AffiliateTable";
import API from "../../api";
import {useSelector} from "react-redux";

export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [affiliates, setAffiliates] = React.useState([]);
    const [pending, setPending] = React.useState(true);
    const [searchText, setSearchText] = React.useState("");
    const [mySponsor, setMySponsor] = React.useState("");
    const apptheme = useSelector((state) => state.user.userTheme);

    React.useEffect(() => {
        const fetchAffiliateInfo = async () => {
            try {
                const res = await API.post("affiliate_info", {}, {headers});
                setAffiliates(res.data.list);
                setMySponsor(res.data.sponsor);
                setPending(false);
            } catch (e) {
                // Handle error
            }
        };
        fetchAffiliateInfo();
    }, []);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredData = affiliates?.filter(
        (row) =>
            row.lastname.toLowerCase().includes(searchText.toLowerCase()) ||
            row.firstname.toLowerCase().includes(searchText.toLowerCase()) ||
            row.created.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="content">
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">

                    {personinfo.personType === "Student" && (
                        <>
                            <div className="col-sm-12 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="px-2 py-3">My Affiliates</h3>
                                        {mySponsor !== "" ? (
                                            <div className="text-center">
                                                <h3 className="fw-bold">{mySponsor}</h3>
                                                <small>My sponsor</small>
                                            </div>
                                        ) : (
                                            <h3 className="fw-bold">No Sponsor</h3>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-8">
                                <div className="card">
                                    <div className="card-body mt-4">
                                        <input
                                            type="search"
                                            className="form-control mb-3"
                                            value={searchText}
                                            onChange={handleSearch}
                                            placeholder="Search by Student name or Date..."
                                        />
                                        <DataTable
                                            responsive
                                            striped
                                            theme={apptheme ? "dark" : undefined}
                                            direction="auto"
                                            subHeaderAlign="right"
                                            subHeaderWrap
                                            selectableRows
                                            columns={AffiliateTable()}
                                            progressPending={pending}
                                            data={filteredData}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {personinfo.personType !== "Student" && (
                        <div className="col-sm-12">
                            <div className="card">
                                <h3 className="px-2 py-3">My Affiliates</h3>
                                <div className="card-body mt-1">
                                    <input
                                        type="search"
                                        className="form-control mb-3"
                                        value={searchText}
                                        onChange={handleSearch}
                                        placeholder="Search by Student name or Date..."
                                    />
                                    <DataTable
                                        responsive
                                        striped
                                        theme={apptheme ? "dark" : undefined}
                                        direction="auto"
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        selectableRows
                                        columns={AffiliateTable()}
                                        progressPending={pending}
                                        data={filteredData}
                                        pagination
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Content;
