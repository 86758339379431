import {useSelector} from "react-redux";
import setPageTitle from "../services/TitleService";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import Content from "../components/Affiliates/Content";
import React from "react";
import UnathorizedRedirect from "../services/UnathorizedRedirect";

export const Affiliates = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('My Affiliations');
	UnathorizedRedirect(personinfo, 'All')
	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<Content personinfo={personinfo}/>
		</div>
	)
}
export default Affiliates;