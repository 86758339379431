import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import FeesContent from "../../components/Fee&Transaction/Fees/FeesContent";
import React from "react";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";

export const Fee = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('My Fees');
	UnathorizedRedirect(personinfo, 'Student')
	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<FeesContent personinfo={personinfo}/>
		</div>
	)
}

export default Fee;
