import API from "../../../api";

export const ProfileArea = (props) => {
    const baseURL = API.defaults.baseURL.slice(0, -4);
    return (
        <>
            {
                (props.profileImgCode !== null) ?
                    <img src={`${baseURL}${props.profileImgCode}`} alt="ProfileImage"/> :
                    <div className="icon-frame"><i className="las la-user"></i></div>
            }
            <h4 className="profileName">{props.alisa}</h4>
            <h5>
                <span className="badge">
                {props.personType}
            </span>
            </h5>

        </>
    )
}
export default ProfileArea;