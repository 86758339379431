import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import React from "react";
import WithdrawFormContent from "../../components/Fee&Transaction/Transaction/WithdrawFormContent";

export const WithdrawalForm = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    setPageTitle('Withdraw Funds');
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <WithdrawFormContent personinfo={personinfo}/>
        </div>
    )
}
export default WithdrawalForm;