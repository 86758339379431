import TopMeunSection from "../Blocks/TopMeunSection";
import React, {useRef} from "react";
import {Context} from "../../context/Contexts";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";
import EditFormComponent from "./EditFormComponent";
import successAlert from "../../services/AlertS/sucess";


export const EditQuestionContent = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [assessment, setAssessment] = React.useState([]);
    const [questions, setQuestions] = React.useState([]);

    const {id} = useParams();
    React.useEffect(() => {
        const req = async (e) => {

            try {
                const res = await API.post('get_question_info', {id}, {headers})

                setAssessment(res.data.assessment)
                setQuestions(res.data.questions)
            } catch (e) {
                errorAlert('Oop', 'Error while getting data!')
            }
        }
        req()
    }, [])


    const [formData, setFormData] = React.useState(questions)

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevState => {
            return {
                ...prevState, [name]: type === "checkbox" ? checked : value
            }
        })
    }

    // const handleQuestionTypeChange = (event) => {
    //     setQuestionType(event.target.value);
    //
    // };

    const editorRef = useRef(null);
    const handleEditorChange = (event, editor) => {
        setFormData(prevState => {
            return {
                ...prevState,
                questionZone: editor.getData()
            }
        })
    };
    const handleQuestionTypeChange = (event) => {
        setQuestions(prevState => {
            return {
                ...prevState,
                type: event.target.value
            }
        })
        handleChange(event)
    };
    const editor = editorRef.current;
    const onsubmit = async (e) => {
        e.preventDefault();
        const qtype = e.target.elements.type;
        const points = e.target.elements.points;
        const duration = e.target.elements.duration;
        const possibleAnn = e.target.elements.answerQ1;
        const possibleAnnID = e.target.elements.answerid;
        const realAnswer = e.target.elements.realAnswerQ1;
        const possibleAnswers = [];
        let newAnswersArray = [];
        if (qtype.value === 'multiple') {
            for (let i = 0; i < possibleAnn.length; i++) {
                possibleAnswers.push(possibleAnn[i].value);
                let answerObject = {
                    answer: possibleAnn[i].value,
                    isanswer: i === parseInt(realAnswer.value),
                    ref: formData.id,
                    answerid: questions.answers.length > 0 ? questions.answers[i].id : null
                };

                newAnswersArray.push(answerObject);
            }
        }

        let editedQuestionObj = {
            points: Number(points.value),
            type: qtype.value,
            duration: Number(duration.value),
            id: questions.id,
            isTemp: true,
            questionZone: formData.questionZone,
            answers: newAnswersArray,
        }
        //Send to api
        // setQuestions(prevState => [...prevState, ...newInputObj]);
        const req = async (e) => {
            try {
                const examid = assessment.uuid;
                const res = await API.post('saved_edited_question', {examid, editedQuestionObj}, {headers})

                if (res.data === 'saved') {
                    successAlert('Nice on', "Question has been saves successufully !")
                }
                ;
            } catch (e) {
                errorAlert('Oop', 'Error while getting data!')
            }
        }
        req();
        //Clear form

        // if (editor) {
        //     editor.setData("");
        // }
        // if (qtype.value === 'multiple') {
        //     // Clear the answerQ1 inputs
        //     const answerInputs = e.target.elements.answerQ1;
        //     Array.from(answerInputs).forEach((input) => {
        //         input.value = "";
        //     });
        //
        //     // Clear the radio button
        //     const radio = e.target.elements.realAnswerQ1;
        //     radio.checked = false;
        //
        // }

    }
    const publishTheQuestion = async () => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure you want proceed?',
            text: "All temporary question will be made accessible to student who which to take the exam. Kindly cross your I's and dot Your T's 😀",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (confirmResult.isConfirmed) {
            try {
                const examid = assessment.uuid;
                const res = await API.post('publish_questions', {examid}, {headers})

                if (res.data === 'saved') {
                    successAlert('Great', 'The questions have been pulished and studens can now access them!')
                }
            } catch (e) {
                errorAlert('Oop', 'An Error occurred!')
            }
        }
    }

    return (<div className='content'>
        <TopMeunSection personinfo={personinfo}/>
        <div className="container-fluid mt-3">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex mb-4 justify-content-between">
                                <h3><a href={`/add-question/${assessment?.course?.uuid}/${assessment?.assessment}`}
                                       className="dynmatxt"><i
                                    className="fas fa-arrow-left"></i></a> {assessment?.title} <small className="text-uppercase text-info">[{assessment?.assessment}]</small></h3>
                            </div>
                            <div className="row">

                                <EditFormComponent question={questions}
                                                   formData={formData} setFormData={setFormData}
                                                   handleChange={handleChange}
                                                   editor={editor} editorRef={editorRef}
                                                   handleQuestionTypeChange={handleQuestionTypeChange}
                                                   publishTheQuestion={publishTheQuestion}
                                                   handleEditorChange={handleEditorChange}
                                                   onsubmit={onsubmit}/>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default EditQuestionContent;