import React from "react";
import API from "../../api";
import warningAlert from "../../services/AlertS/warning";
import {Context} from "../../context/Contexts";
import TopMeunSection from "../Blocks/TopMeunSection";

export const Content = ({personinfo}) => {
    const [notifications, setNotifications] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(2);
    const [canLoadMore, setCanLoadMore] = React.useState(true);
    const {user} = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };

    const loadNotification = async (page, max) => {
        const req = async (e) => {
            try {
                const res = await API.post(
                    "get_school_message",
                    {page: page, max: max},
                    {headers}
                );
                setNotifications(prevState => {
                    const newData = res.data.filter(newItem => {
                        return !prevState.some(prevItem => prevItem.uuid === newItem.uuid);
                    });
                    return [...prevState, ...newData];
                });
                // setNotifications((prevState) => [...prevState, ...res.data]);
            } catch (e) {
                warningAlert("Oop!", "There are no more messages to load!");
                setCanLoadMore(false);
            }
        }
        req()
    };

    const handeLoadMore = () => {
        if (canLoadMore) {
            setPageNumber((prevState) => prevState + 1);
            loadNotification(pageNumber, 10);
        }
    };

    React.useEffect(() => {
        loadNotification(1,10)
    }, []);

    const formattedDate = (dateString) => {
        return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };

    const notificationList = notifications.map((item, index) => (
        <a
            key={item.uuid}
            href={`notification-board-message/${item.uuid}`}
            className="list-group-item list-group-item-action mb-2 notification-item"
        >
            <div className="d-flex w-100 justify-content-between">
                <div className="flex-grow-1 text">
                    <h6 className="mb-1 fw-bold">{item.title}</h6>
                </div>
                <small>{formattedDate(item.created)}</small>
            </div>
        </a>
    ));

    return (
        <div className="content">
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body overflow-auto">
                                <div className="list-group">{notificationList}</div>
                                <button onClick={handeLoadMore} className="btn btn-secondary w-100">
                                    Load more
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Content;
