import ProfileSection from "./ProfileSection";
import MenuSection from "./MenuSection";
import {useSelector} from "react-redux";
import React from "react";

export const LeftSide = ({personinfo}) => {
    const [siMobile, setSiMobile] = React.useState(false);
    const isLeftShowed = useSelector(state => state.user.toggleLeftside);
    const cssStyle = {
        display: isLeftShowed ? "block" : "none",
        position: isLeftShowed ? "absolute" : "unset",
        // zIndex:999999999999
    }
    React.useEffect(() => {

        const handleResize = () => {
            setSiMobile(window.innerWidth <= 991);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <>
            {siMobile ? (
                <div className="leftside" style={cssStyle}>
                    <ProfileSection/>
                    <MenuSection key={Math.random()} personinfo={personinfo}/>
                </div>
            ) : (
                <div className="leftside">
                    <ProfileSection/>
                    <MenuSection key={Math.random()} personinfo={personinfo}/>
                </div>
            )}


        </>
    )
}
export default LeftSide;