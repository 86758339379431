import React from "react";

const NotificationTable = ({ notifications }) => {
	const formattedDate = (dateString) => {
		return new Date(dateString).toLocaleDateString("en-US", {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
		});
	};

	return [
		{
			name: "Object",
			selector: (row) => row.object,
			sortable: true,
		},
		{
			name: (
				<>
					Read <i className="fas fa-envelope-open px-1"></i> |
					Un-read <i className="fas fa-envelope px-1"></i>
				</>
			),
			selector: (row) => row.isRead ? (
				<><i className="fas fa-envelope-open fa-lg"></i></>
			) : (
				<><i className="fas fa-envelope fa-lg"></i></>
			),
			sortable: true,
		},

		{
			name: "Description",
			selector: (row) => row.description,
			sortable: true,
		},
		{
			name: "Date",
			selector: (row) => formattedDate(row.created),
			sortable: true,
		},
	];
};

export default NotificationTable;
