import setPageTitle from "../services/TitleService";
import {useSelector} from "react-redux";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import Content from "../components/NotificationBoard/Content";
import React from "react";

export const NotificationBoardPage = () => {
	setPageTitle('School messages');
	const personinfo = useSelector(state => state.user.userInfo);

	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo} />
			<Content personinfo={personinfo} />
		</div>
	);
};

export default NotificationBoardPage;
