import React from "react";
import {Context} from "../../../context/Contexts";
import TopMeunSection from "../../Blocks/TopMeunSection";
import DataTable from "react-data-table-component";
import Table from "./Table";
import API from "../../../api";
import {useSelector} from "react-redux";
import warningAlert from "../../../services/AlertS/warning";

export const HistoryContent = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [searchText, setSearchText] = React.useState('');
    const [pending, setPending] = React.useState(true);
    const [transactions, setTransactions] = React.useState([]);


    React.useEffect(() => {
        const timeout = setTimeout(() => {
                    const req = async (e) => {

                        try {
                            const res = await API.post('transaction_history', {}, {headers})
                            setTransactions(res.data);
                            setPending(false);
                        } catch (e) {

                        }
                    }
                    req()
                }
                ,
                1500
            )
        ;
        return () => clearTimeout(timeout);
    }, []);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };
    const filteredData = transactions.filter((row) =>
        row.amount.toLowerCase().includes(searchText.toLowerCase()) ||
        row.transtype.toLowerCase().includes(searchText.toLowerCase()) ||
        row.reference.toLowerCase().includes(searchText.toLowerCase()) ||
        row.senderLastname.toLowerCase().includes(searchText.toLowerCase()) ||
        row.senderFirstname.toLowerCase().includes(searchText.toLowerCase())
    );
    const apptheme = useSelector(state => state.user.userTheme);
    const [pageCount, setPageCount] = React.useState(2);
    const [canLoadMore, setCanLoadMore] = React.useState(true);
    const handleLoadMore = () => {
        if (canLoadMore) {
            setPageCount(prevState => {
                return (prevState + 1)
            });
            const req = async (e) => {

                try {
                    const res = await API.post('transaction_history', {page: pageCount,maxnumber:10}, {headers});
                    setTransactions(prevState => {
                        const newData = res.data.filter(newItem => {
                            return !prevState.some(prevItem => prevItem.uuid === newItem.uuid);
                        });
                        return [...prevState, ...newData];
                    });
                } catch (e) {
                    warningAlert('Oop!', 'There are no more data to load!');
                    setCanLoadMore(false)
                }

            }
            req()
        }else {
            warningAlert('Oop!', 'There are no more library asset to load!');
        }

    }
    const formattedDate = (dateString) => {
        return (new Date(dateString).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            })
        )
    }
    const ExpandedComponent = ({data}) => (
        <div>
            <p>
                Reference N°: <b>{data.reference}</b>
            </p>
            <p>
                Amount (#): <b>{parseInt(data.amount).toLocaleString()}</b>
            </p>
            <p>
                Sender: <b>{data.senderFirstname}  {data.senderLastname}</b>
            </p>
            <p>
                Reciever: <b>{data.recieverFirstname} {data.recieverLastname}</b>
            </p>

            <p>
                Details: <b>{data.details}</b>
            </p>
            <p>
                Issued on: <b>{formattedDate(data.created)}</b>
            </p>


        </div>
    );
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <h3>Transactions History</h3>
                                <div className="mt-4">
                                    <input
                                        type="search"
                                        className="form-control mb-3"
                                        value={searchText}
                                        onChange={handleSearch}
                                        placeholder="Search by Reference N°, Transaction Type or Amount..."
                                    />
                                    <DataTable
                                        responsive
                                        striped
                                        theme={apptheme ? "dark" : undefined} // Fixed theme assignment
                                        direction="auto"
                                        subHeaderAlign="right"
                                        subHeaderWrap
                                        columns={Table(personinfo)}
                                        progressPending={pending}
                                        expandableRows
                                        expandableRowsComponent={ExpandedComponent}
                                        data={filteredData}
                                        pagination
                                    />
                                    <button onClick={handleLoadMore}
                                            className="btn  my-2 btn-outline-secondary w-100 btn-sm">Load
                                        more
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HistoryContent;