import {useParams} from "react-router-dom";
import React from "react";
import {Context} from "../../context/Contexts";
import API from "../../api";
import TopMeunSection from "../../components/Blocks/TopMeunSection";
import AnsweredScriptLecturer from "./AnsweredScriptLecturer";
import Swal from "sweetalert2";
import successAlert from "../../services/AlertS/sucess";

export const MarkingScriptContent = ({personinfo}) => {
    const {id} = useParams();
    const [examScripts, setExamScripts] = React.useState([]);
    const [reloadExamScripts, setReloadExamScripts] = React.useState(false);
    const {user} = React.useContext(Context);
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };

    React.useEffect(() => {
        const req = async (e) => {
            try {
                const res = await API.post("get_exam_scripts", {id}, {headers});
                setExamScripts(res.data);
            } catch (e) {
            }
        };
        req();
    }, [reloadExamScripts]);

    const updateStudentPoint = (referenceCode, qUuid, newStudentPoint) => {
        setExamScripts((prevScripts) => {
            const updatedScripts = {...prevScripts};
            if (updatedScripts[referenceCode]) {
                const updatedScript = updatedScripts[referenceCode].find(
                    (script) => script.qUuid === qUuid
                );
                if (updatedScript) {
                    updatedScript.studentPoint = newStudentPoint;
                    updatedScript.qMarked = true
                }
            }
            return updatedScripts;
        });
    };

    const studentResponses = Object.entries(examScripts).map(
        ([matricnoIndex, scripts], index) => (
            <div className="p-4" key={matricnoIndex}>
                <span className="badge bg-info mb-2">{matricnoIndex}</span>
                {scripts.map((item, subIndex) => (
                    <AnsweredScriptLecturer
                        key={subIndex}
                        info={item}
                        updateStudentPoint={updateStudentPoint}
                    />
                ))}
            </div>
        )
    );

    const handleSaveProgress = async () => {
        Swal.fire({
            title: 'Sit tight',
            text: 'While we save your progress...',
            allowOutsideClick: false,
            onOpen: () => {
                Swal.showLoading();
            }
        });
        try {
            setTimeout(async () => {
                const res = await API.post("save_marked_scripts", {examScripts, id}, {headers});
                if (res.data === 'saved') {
                    setReloadExamScripts(prevState => !prevState)

                    Swal.close();
                    successAlert('Records saved', 'The scores you have entered have been saved')
                }
            }, 1500);
        } catch (e) {
        }
    };

    return (
        <div className="content">
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h3><a href="/list-scripts" className="dynmatxt"><i
                                        className="fas fa-arrow-left"></i></a> Scripts</h3>
                                    <button onClick={handleSaveProgress} className="btn-success btn btn-sm">Save
                                        progress
                                    </button>
                                </div>
                                <div className="mt-4">
                                    <div className="col-sm-12 px-4">
                                        <div
                                            className="mx-3 scriptViewZoneLect">{studentResponses.length > 0 ? studentResponses : (
                                            <h1 className="text-center fw-bold mt-5">No more script to mark!</h1>
                                        )}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarkingScriptContent;