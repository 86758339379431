import React from "react";
import DescriptionModal from "./DescriptionModal";

export const AssetItem = ({
                              asset,
                              baseurl,
                              personinfo,
                              handleToggleModal,
                          }) => {
    const cssStyle = {
        backgroundColor: asset.color,
    };

    const formattedDate = (dateString) => {
        return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const [modalDescptionShow, setModalDescptionShow] = React.useState(false);

    const handleToggleDescptionModal = () => {
        setModalDescptionShow((prevState) => !prevState);
    };
    return (
        <>
            <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-4 mb-3">
                <div className="card library-assets">
                    <div className="card-body pb-1" style={cssStyle}>
                        <h6
                            className="text-white text-uppercase font-weight-bold pb-2"
                            title={asset.title}
                        >
                            {asset.title.length > 20
                                ? asset.title.substring(0, 20) + "..."
                                : asset.title}
                        </h6>
                        <p className="text-white mb-0">
                            <small>
                                Author:{" "}
                                <b>
                                    {asset.firstname !== null && asset.lastname !== null
                                        ? `${asset.firstname} ${asset.lastname}`
                                        : "SYSTEM"}
                                </b>
                            </small>
                        </p>

                        <div className="d-flex justify-content-between flex-row bd-highlight text-white">
                            <div className="p-1 text-dark bd-highlight">
                                <small>
                                    <i className="fas fa-calendar-check"></i>
                                    &nbsp;
                                    {formattedDate(asset.datePublished)}
                                </small>
                                <br />
                                <span className="text-center">
                  <i className="las la-layer-group"></i> {asset.category}
                </span>
                            </div>
                            <div className="p-1 text-dark bd-highlight">
                                <a href={baseurl + asset.location + asset.filename}
                                    download="download"
                                    target="_blank"
                                    className="text-white text-end" rel="noreferrer"
                                >
                                    <i className="fas fa-download"></i>
                                </a>{" "}
                                <br />
                                {personinfo.personType === "Lecturer" && personinfo.id === asset.authorId && (
                                    <button
                                        onClick={() => handleToggleModal(asset)}
                                        className="btn btn-sm btn-outline-warning"
                                    >
                                        <i className="fas fa-pencil"></i>
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="card-footer pb-2 pt-1 bg-white">
                        <div className="d-flex flex-row bd-highlight justify-content-between text-white">
                            <div className="p-1 text-dark bd-highlight border-right">
                                <small
                                    onClick={handleToggleDescptionModal}
                                    className="text-info cursor"
                                >
                                    View more info
                                </small>
                            </div>

                            <div className="p-1 text-dark bd-highlight border-right">
                                <small className="assettype text-uppercase">
                                    <b>{asset.type}</b>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DescriptionModal
                asset={asset}
                handleToggleDescptionModal={handleToggleDescptionModal}
                modalDescptionShow={modalDescptionShow}
                setModalDescptionShow={setModalDescptionShow}
            />
        </>
    );
};

export default AssetItem;
