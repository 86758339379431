export const StudentMenu = () => {
	return (
			<ul>
				<li><a href="/dashboard">Dashboard</a></li>
				<li><a>Academics</a>
					<div className="submenu shadow">
						<p className="menu-title">Academics</p>
						<ul>
							<li><a href="/courses">My course</a></li>
							<li><a href="/assessments">Exams & Tests</a></li>
							<li><a href="/score-board">Score board</a></li>
						</ul>
					</div></li>
				<li><a href="/e-library">E-Library</a></li>
				<li><a href="/fees">Fees</a></li>
				<li className="navi-menu">
					<a>Transactions </a>
					<div className="submenu shadow">
						<p className="menu-title">Transactions</p>
						<ul>
							<li><a href="/transfer-funds">Transfer</a></li>
							<li><a href="/withdraw-funds">Withdraw</a></li>
							<li><a href="/transaction-history">Transactions history</a></li>
						</ul>
					</div>
				</li>
			</ul>
	)
}
export default StudentMenu;