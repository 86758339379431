import React from "react";
import {Context} from "../../context/Contexts";
import Slider from "react-slick";
import WalletCard from "../Dashboard/WalletCard";
import API from "../../api";
import TopMeunSection from "../Blocks/TopMeunSection";
import generateUniqueId from "../../services/generateUniqueId";
import {usePaystackPayment} from "react-paystack";
import errorAlert from "../../services/AlertS/error";
import Swal from "sweetalert2";

export const TopupWalletContent = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const [userwallets, setUserwallets] = React.useState([]);
    // const [allwallets, setAllwallets] = React.useState([]);
    const walletSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const walletElement = (
        userwallets.length > 1 ?
            <Slider {...walletSettings}>
                {userwallets.map((item, index) => (
                    <WalletCard key={index} {...item} />
                ))}
            </Slider>
            :
            userwallets.map((item, index) => (
                <WalletCard key={index} {...item} />
            ))
    );
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const initForm = {
        amount: ''
    }
    const [formData, setFormData] = React.useState(initForm);
    const [calsum, setCalcum] = React.useState('');

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        const fee = parseInt(value) * 0.015 + 100 + 50;
        setCalcum(Math.round(value - fee))
        setFormData(prevState => {
            return {
                ...prevState, [name]: type === "checkbox" ? checked : value
            }
        })
    }

    React.useEffect(() => {
        API.post('wallet_info', {}, {headers})
            .then(res => setUserwallets(res.data))
        ;

    }, []);
    const key = process.env.REACT_APP_PAY_STACK;
    const config = {
        reference: generateUniqueId(),
        email: personinfo.email,
        amount: parseInt(formData.amount * 100),
        publicKey: key,
    };
    const onSuccess = (reference) => {
        // reference.status === "success" &&
        const req = async (e) => {

            try {
                const res = await API.post('topupmywallet', {calsum}, {headers})
                setFormData(initForm);
                Swal.fire({
                    icon: 'success',
                    title: 'Operation successful',
                    text: "Your wallet will be credited shortly!",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    willClose: () => {
                        window.location.replace(`/top-wallet`)
                    }
                });
            } catch (e) {
                errorAlert('Operation un-successful', 'An error occurred during the process !')
            }
        }
        req()
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        // console.log('closed')
    }
    const initializePayment = usePaystackPayment(config);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.amount < 5000) {
            return errorAlert('Amount error', "The minimum amount allowed is NGN 5000")
        }
        initializePayment(onSuccess, onClose)
    }
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-sm-12 col-lg-6 offset-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="">
                                    <h3>Top up my wallet</h3>
                                    <div className="mb-3">
                                        {userwallets.length > 0 && (
                                            <>
                                                <h6>My balance</h6>
                                                {walletElement}
                                                <hr/>
                                            </>
                                        )}
                                    </div>
                                    <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
                                        <div className="form-floating mb-4">
                                            <input type="text" className="form-control numberField" id="recieverCode"
                                                   name="amount"
                                                   required="required"
                                                   value={formData.amount} onChange={handleChange}
                                                   placeholder="&nbsp;"/>
                                            <label htmlFor="recieverCode">Amount</label>
                                        </div>
                                        {calsum !== '' && <span
                                            className="text-info">Your wallet will be credited with <b>{calsum} NGN</b></span>}

                                        <button className="btn btn-primary mt-3 w-100" type="submit">Submit <i
                                            className="las la-paper-plane"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}