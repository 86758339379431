import React from "react";
import Select from 'react-select';
// import AsyncSelect from 'react-select/async';
import {Controller} from "react-hook-form";

export const AcademicInfo = ({
                                 errorMessageRender,
                                 formStep,
                                 control,
                                 errors,
                                 department,
                                 certifcates,
                                 level,
                                 sponsorCode,
                                 programs,
                                 admissionType,
                                 schoolDiscovery
                             }) => {
    // const [programSelecteId, setProgramSelecteId] = React.useState(programs[0].id)




    // const handleProgramChange = (e) => {
    //     const {value} = e.target;
    //     setProgramSelecteId(value);
    //     filterDepartment()
    //     // Do something with the selected option here
    // };
    // const filterDepartment = (inputValue = null) => {
    //     let filteredOptions = departmentOptions;
    //
    //     if (inputValue) {
    //         filteredOptions = filteredOptions.filter(
    //             (i) =>
    //                 i.label.toLowerCase().includes(inputValue.toLowerCase()) &&
    //                 i.programid === programSelecteId
    //         );
    //     } else if (inputValue === null) {
    //         filteredOptions = filteredOptions.filter(
    //             (i) => i.programid === programSelecteId
    //         );
    //     }
    //
    //     filteredOptions.sort((a, b) => a.label.localeCompare(b.label));
    //
    //     return filteredOptions;
    // };

    // const promiseOptions = (inputValue) =>
    //     new Promise((resolve) => {
    //         resolve(filterDepartment(inputValue));
    //     });

    const departmetnArray = Object.values(department);
    const departmentOptions = departmetnArray.map((d) => ({
        value: d.uuid,
        label: `${d.title.toUpperCase()}`,
        programid: d.programid
    }));

    const certificateArray = Object.values(certifcates);
    const certificateOptions = certificateArray.map((d) => ({
        value: d.id,
        label: d.libelle
    }));

    const admissionTypeOptions = admissionType.map((d, index) => (
        <div key={index} className="form-check form-switch">
            <input
                className="form-check-input"
                role="switch" aria-checked="false"
                name="admissionType"
                type="radio"
                value={d.id}
                id={`admissionType-${index}`}
                defaultChecked={index === 0}
                required
            />
            <label className="form-check-label" htmlFor={`admissionType-${index}`}>
                {d.libelle}
            </label>
        </div>
    ));

    const levelOptions = level.map((d, index) => (
        <div key={index} className="form-check form-switch">
            <input
                className="form-check-input"
                role="switch" aria-checked="false"
                name="level"
                type="radio"
                value={d.id}
                id={`level-${index}`}
                defaultChecked={index === 0}
                required
            />
            <label className="form-check-label" htmlFor={`level-${index}`}>
                {d.title}
            </label>
        </div>
    ));

    const programsOptions = Object.values(programs).map((d, index) => (

        <div key={index} className="form-check form-switch">
            <input
                className="form-check-input"
                role="switch" aria-checked="false"
                name="program"
                type="radio"
                value={d.id}
                // onChange={(e) => handleProgramChange(e)}
                id={`program-${index}`}
                defaultChecked={index === 0}
                required
            />
            <label className="form-check-label" htmlFor={`program-${index}`}>
                {d.libelle}
            </label>
        </div>
    ));

    const schoolDiscoveryArray = Object.values(schoolDiscovery);
    const schoolDiscoveryOptions = schoolDiscoveryArray.map((d) => ({
        value: d.id,
        label: d.libelle
    }));

    return (
        <div className={formStep !== 2 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">ACADEMIC INFORMATION</h3>
            <p className="small text-secondary text-center">
                Kindly fill this section as well
            </p>
            <p className="text-end dynmatxt">STEP: <small className="fw-bold">{formStep}/4</small></p>
            <div className="row">
                <div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="">What type of enrollment are you applying for? <span
                        className="text-danger">*</span></label>
                    <div className="d-md-flex justify-content-md-between">
                        <Controller
                            name="admissionType"
                            control={control}
                            rules={{required: 'Please select an option'}}
                            render={({field}) => <>{admissionTypeOptions}</>}
                        />
                        {errors.admissionType && errorMessageRender(errors.admissionType.message)}
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="">Which level are you applying for? <span className="text-danger">*</span></label>
                    <div className="d-md-flex justify-content-md-between">
                        <Controller
                            name="level"
                            control={control}
                            rules={{required: 'Please select an option'}}
                            render={({field}) => <>{levelOptions}</>}
                        />
                        {errors.lastname && errorMessageRender(errors.lastname.message)}
                    </div>
                </div>

                <div className="col-sm-12  mb-4">
                    <label htmlFor="discipline">Select program type <span className="text-danger">*</span></label>
                    <div className="d-md-flex justify-content-md-between"><Controller
                        name="program"
                        control={control}
                        render={({field}) => (
                            // <Select options={programsOptions} value={preselectedProgram} hideSelectedOptions={true}
                            //        {...field}   />
                            <>{programsOptions}</>
                        )}
                    />
                    </div>
                    {errors.program && errorMessageRender(errors.program.message)}
                </div>

                <div className="col-sm-12 mb-4">
                    <label htmlFor="department">Select your Department <span className="text-danger">*</span></label>
                    <Controller
                        name="department" // Set the name for your form field
                        control={control}
                        rules={{required: 'Please select an option'}}
                        render={({field}) => (
                            <Select  {...field} options={departmentOptions}/>
                        )}
                    />
                    {errors.department && errorMessageRender(errors.department.message)}
                </div>

                <div className="col-sm-12 col-md-7 mb-4">
                    <label htmlFor="department">Which is your most recent certificate? <span
                        className="text-danger">*</span></label>
                    <Controller
                        name="certificate" // Set the name for your form field
                        control={control}
                        rules={{required: 'Please select an option'}}
                        render={({field}) => (
                            <Select  {...field} options={certificateOptions}/>
                        )}
                    />
                    {errors.certificate && errorMessageRender(errors.certificate.message)}
                </div>
                <div className="col-sm-12 col-md-5 mb-4">
                    <label htmlFor="noofsitting">No of sittings</label>
                    <select name="noofsitting" className="form-control" id="" required="" aria-required="true">
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                </div>
                {!sponsorCode && (<div className="col-sm-12 col-md-12 mb-4">
                    <label htmlFor="noofsitting">How did you hear about the school</label>
                    <Controller
                        name="schoolDiscovery" // Set the name for your form field
                        control={control}
                        render={({field}) => (
                            <Select {...field} options={schoolDiscoveryOptions} isClearable={true}/>
                        )}
                    />
                    {errors.schoolDiscovery && errorMessageRender(errors.schoolDiscovery.message)}
                </div>)}
            </div>
        </div>
    );
};

export default AcademicInfo;
