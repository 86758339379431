import React from "react";
import Select from "react-select";
import {Controller} from "react-hook-form";

export const GuardianInfo = ({formStep, countries, register,control, errors, errorMessageRender}) => {

    const countriesArray = Object.values(countries);
    // const callCodeOptions=[];
    const callCodeOptions = countriesArray.map((d) => (
        {value: d.id, label: `${d.flag} ${d.callcode}`}
    ));
    const preselectedValue = {value: 157, label: "🇳🇬 +234"};

    return (
        <div className={formStep !== 4  ? "d-none" : undefined}>
            <h3 className="primary-color text-center text-uppercase">Guardian/Next of kin Info {formStep}/4</h3>
            <p className="small text-secondary text-center">Kindly fill the information of your guradian (A Parent,
                Elder
                Sibling, etc)</p>
            <p className="text-end dynmatxt">STEP: <small className="fw-bold">{formStep}/4</small></p>
            <div className="row">
                <div className="col-sm-12 mb-4">

                    <label htmlFor="gfullname" className="active">What Your Guardian Fullname ? <span
                        className="text-danger">*</span></label>

                    <input id="gfullname" className="form-control" {...register("gfullname", {
                        required: {
                            value: true,
                            message: 'Please enter the guardians fullname'
                        }
                    })} type="text"/>
                    {errors.gfullname && errorMessageRender(errors.gfullname.message)}
                </div>
                <div className="col-sm-12 mb-4">

                    <label htmlFor="gnumber" className="active">Your Guardian telephone number? <span
                        className="text-danger">* <small>(No space ,no + sign or point)</small></span></label>
                    <div className="input-group mb-3">
                            <span className="input-group-text p-0" id="basic-addon1">
                                <Controller
                                    name="gtelephonecallcode"
                                    control={control}
                                    defaultValue={preselectedValue}
                                    render={({field}) => (
                                        <Select {...field} options={callCodeOptions}/>
                                    )}
                                />
                            </span>
                        <input type="text" {...register("gnumber", {
                            required: {
                                value: true,
                                message: 'Please enter the guardians phone number'
                            }
                        })} className="form-control numberField"
                               placeholder="(without country code)"/>
                    </div>
                    {errors.telephonenumber && errorMessageRender(errors.telephonenumber.message)}
                </div>
                <div className="col-sm-12 col-md-6 mb-4">
                    <label htmlFor="gemail">What Your Guardian's email? </label>
                    <input id="gemail" className="form-control" name="gemail" type="email" {...register("gemail", {
                        required: {
                            // value: false,
                            message: 'Please enter the guardians email'
                        }
                    })}/>

                </div>
                <div className="col-sm-12 col-md-6 mb-4">

                    <label htmlFor="grelation">Who is the Guradian to you? <span
                        className="text-danger">*</span></label>

                    <select name="grelation" className="form-control" id="grelation" required=""
                            {...register("grelation", {
                                required: {
                                    value: true,
                                    message: 'Please enter the guardians relationship to you!'
                                }
                            })} >
                        <option value=""></option>
                        <option>Father</option>
                        <option>Mother</option>
                        <option>Uncle</option>
                        <option>Aunty</option>
                        <option>Brother</option>
                        <option>Sister</option>
                        <option>Cousin</option>
                        <option>Husband</option>
                        <option>Legal Tutor</option>
                    </select>
                    {errors.grelation && errorMessageRender(errors.grelation.message)}

                </div>
                <div className="col-sm-12">
                    <label htmlFor="gaddress" className="active">What is your Guradian's address? </label>

                    <input id="gaddress" className="form-control" name="gaddress" type="text" {...register("gaddress", {
                        required: {

                            message: 'Please enter the guardians relationship to you!'
                        }
                    })}/>

                </div>
            </div>
        </div>
    )
}
export default GuardianInfo;