export const ShortCutsLayout = (props) => {
    return (

                <li className="nav-item">
                    <a className="nav-link" href={props.link} title={props.name}>
                        <img src={props.imagelink} alt="shortcut0image"/>
                        <span className="pl-detail-text"> {props.name}</span>
                    </a>
                </li>


    )
}

export default ShortCutsLayout;