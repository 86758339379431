import React from "react";
import {useSelector} from "react-redux";
import setPageTitle from "../services/TitleService";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import Content from "../components/ProfileSetting/Content";

export const ProfilePage = () => {
    const personinfo = useSelector(state => state.user.userInfo);

    React.useEffect(() => {

        const req = async (e) => {
            setPageTitle('My Profile');

        }
        req()

    }, []);
    return (
        <div className="d-flex position-relative">
            <LeftSide  personinfo={personinfo}/>
            <Content  personinfo={personinfo}/>
        </div>
    )
}
export default ProfilePage;