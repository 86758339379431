import React, { useEffect, useState } from "react";

export const MultiChoiceOption = ({ answers, answerData, setAnswerData }) => {
    const [shuffledAnswers, setShuffledAnswers] = useState([]);

    useEffect(() => {
        shuffleAnswers(); // Shuffle the answers when the component is rendered
    }, [answers]);

    const shuffleAnswers = () => {
        const shuffled = Object.values(answers).sort(() => Math.random() - 0.5);
        setShuffledAnswers(shuffled);
    };

    const handleSelectAnswer = (event) => {
        const selectedAnswer = event.target.value;
        const answerValue = event.target.getAttribute("data-value");
        setAnswerData((prev) => ({
            ...prev,
            studentAnswer: selectedAnswer,
            answerValue: answerValue,
        }));
    };

    const liOptions = shuffledAnswers.map((item, index) => {
        return (
            <li key={item.uuid}>
                <input
                    type="radio"
                    id={index}
                    data-value={item.answer}
                    name="answer"
                    value={item.uuid}
                    className="answer"
                    onChange={handleSelectAnswer}
                />
                <label htmlFor={index}>{item.answer}</label>
            </li>
        );
    });

    return (
        <>
            <ul>{liOptions}</ul>
        </>
    );
};

export default MultiChoiceOption;
