import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import React from "react";
import AssessmentScoreContent from "./AssessmentScoreContent";
import {useParams} from "react-router-dom";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";

export const AssessmentScore = ({view}) => {
    const personinfo = useSelector(state => state.user.userInfo);
    setPageTitle('My Scores');
    if (personinfo.personType !== 'Student') {
        UnathorizedRedirect()
    }
    const {id} = useParams();
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <AssessmentScoreContent view={view} id={id} personinfo={personinfo}/>
        </div>
    )
}
export default AssessmentScore;