import React from "react";
import API from "../../api";

export const AgentInfo = ({personinfo,headers}) => {
    const [personDetail, setPersonDetail] = React.useState([]);

    React.useEffect(() => {
        API.post('agent-info', {}, {headers})
            .then(res => setPersonDetail(res.data))
    }, []);
    return (
        <ul className="list-group mainnetinfo">
            <li className="list-group-item d-flex justify-content-between">
						<span>Fullname
						</span>
                <b>{personinfo.firstname} {personinfo.lastname}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
						<span>Agent code
						</span>
                <b>{personDetail.code}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
						<span>Gender
						</span>
                <b>{personDetail.sex ? 'Male' : 'Female'}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
						<span>Email
						</span>
                <b>{personDetail.email}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
						<span>Telephone
						</span>
                <b>{personDetail.telephone}</b>
            </li>
            <li className="list-group-item d-flex justify-content-between">
						<span>Whatsapp
						</span>
                <b>{personDetail.whatsapptelephone}</b>
            </li>
            {/*<li className="list-group-item d-flex justify-content-between">*/}
			{/*			<span>Department*/}
			{/*			</span>*/}
            {/*    <b>{personDetail.departmentTitle} | {personDetail.departmentCode}</b>*/}
            {/*</li>*/}
        </ul>
    )
}
export default AgentInfo;