export default [

    {
        id: 0,
        name: 'Score sheets',
        imagelink: "/dist/img/students.png",
        link: "/courses",
    },
    {
        id: 1,
        name: 'AI',
        imagelink: "/dist/img/robot.png",
        link: "/ai",
    },
    {
        id: 2,
        name: 'Whats new',
        imagelink: "/dist/img/whats-new.png",
        link: "/#",
    },
];