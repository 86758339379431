import React, {useEffect, useState} from "react";
import API from "../../api";

export const NotificationBoard = ({ headers }) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const req = async (e) => {

            try {
                const res = await API.post('get_school_message', {page: 1, max: 6 }, {headers})
                setNotifications(res.data);
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        }
        req()

    }, []);

    const formattedDate = (dateString) => {
        return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    };

    const notificationList = notifications.map((item, index) => (
        <a href={`notification-board-message/${item.uuid}`} className="list-group-item list-group-item-action  notification-item" key={item.uuid}>
            <div className="d-flex w-100 justify-content-between">
                <div className="flex-grow-1 text">
                    <marquee>
                        <h6 className="mb-1 fw-bold text-white">{item.title}</h6>
                    </marquee>
                </div>
                <small className="text-white">{formattedDate(item.created)}</small>
            </div>
        </a>
    ));

    return (
        <div className="col-sm-12 col-md-6 col-lg-6 mt-4 mb-3">
            <div className="card activities-card feedbox">
            <h4 className="card-header">
                <div className="text-white d-inline">
                    Message board
                </div>
                <div className="d-inline float-end">
                    <a href="/notification-board-messages" className="notificationboard-viewmore"><span>View more</span> <i className="fas fa-fas fa-arrow-right"></i></a>
                </div>
            </h4>
            <div className="card-body overflow-y-auto position-relative">
                <div className="list-group">
                    {loading ? (<h1 className="text-center text-white">
                        <i className="fas fa-spinner fa-spin-pulse"></i>
                    </h1>) : (notifications.length > 0 ? (notificationList) : (<p>No notifications found</p>))}
                </div>

            </div>
        </div>
        </div>
    );
};

export default NotificationBoard;
