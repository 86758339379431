import TopMeunSection from "../Blocks/TopMeunSection";
import React, {useRef} from "react";
import {Context} from "../../context/Contexts";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";
import FormComponent from "./FormComponent";
import successAlert from "../../services/AlertS/sucess";


export const AddQuestionContent = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [assessment, setAssessment] = React.useState([]);
    const [questions, setQuestions] = React.useState([]);
    const [questionType, setQuestionType] = React.useState('multiple');
    const [editMode, setEditMode] = React.useState(false);

    const {id} = useParams();
    const {asstype} = useParams();
    React.useEffect(() => {
        const req = async (e) => {

            try {
                let url = (asstype === 'test') ? '/test/add_question' : (asstype === 'exam') ? '/exams/add_question' : '';

                const res = await API.post(url, {id}, {headers})
                if (res.data.assessment === null) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'No Assessment',
                        text: "You can't add question as the assessment as not been created kindly contact the school admin to create it,before you can proceed to add questions.",
                        showConfirmButton: false,
                        timer: 10000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                        willClose: () => {
                            window.location.replace('/courses');
                        }
                    });
                }
                setAssessment(res.data.assessment)
                setQuestions(res.data.questions)
            } catch (e) {
                errorAlert('Oop', 'Error while getting data!')
            }
        }
        req()
    }, [])
    var randomFixedInteger = function (length) {
        return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
    }
    const questionReference = 'EQ-' + randomFixedInteger(7);
    const initForm = {
        id: questionReference, duration: '', type: '', points: '', questionZone: '', answers: []
    }
    const [formData, setFormData] = React.useState(initForm)

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevState => {
            return {
                ...prevState, [name]: type === "checkbox" ? checked : value
            }
        })
    }

    const handleQuestionTypeChange = (event) => {
        setQuestionType(event.target.value);

    };

    const editorRef = useRef(null);
    const handleEditorChange = (event, editor) => {
        setFormData(prevState => {
            return {
                ...prevState,
                questionZone: editor.getData()
            }
        })
    };
    // const toggleEditMode = () => {
    //     setEditMode(prevState => {
    //         return !prevState
    //     })
    // }
    const onsubmit = async (e) => {
        e.preventDefault();
        const qtype = e.target.elements.qtype;
        const points = e.target.elements.points;
        const duration = e.target.elements.duration;
        const possibleAnn = e.target.elements.answerQ1;
        const realAnswer = e.target.elements.realAnswerQ1;
        const possibleAnswers = [];
        let newAnswersArray = [];
        if (qtype.value === 'multiple') {
            for (let i = 0; i < possibleAnn.length; i++) {
                possibleAnswers.push(possibleAnn[i].value);

                let answerObject = {
                    answer: possibleAnn[i].value,
                    isanswer: i === parseInt(realAnswer.value),
                    ref: formData.id,
                };

                newAnswersArray.push(answerObject);
            }
        }

        let newQuestionObj = {
            points: Number(points.value),
            type: qtype.value,
            duration: Number(duration.value),
            ref: questionReference,
            isTemp: true,
            questionZone: formData.questionZone,
            answers: newAnswersArray,
        }
        //Send to api
        setQuestions(prevState => [...prevState, newQuestionObj]);
        const req = async (e) => {
            try {
                const examid = assessment.uuid;
                const res = await API.post('save/new_question', {examid, newQuestionObj}, {headers})

                if (res.data === 'saved') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Nice on',
                        text: "Question has been saves successufully !",
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                        willClose: () => {
                            // window.location.reload()

                        }
                    });
                }
                ;
            } catch (e) {
                errorAlert('Oop', 'Error while getting data!')
            }
        }
        req();

        //Clear form
        setFormData(initForm);
        const editor = editorRef.current;
        if (editor) {
            editor.setData("");
        }
        if (qtype.value === 'multiple') {
            // Clear the answerQ1 inputs
            const answerInputs = e.target.elements.answerQ1;
            Array.from(answerInputs).forEach((input) => {
                input.value = "";
            });

            // Clear the radio button
            const radio = e.target.elements.realAnswerQ1;
            radio.checked = false;

        }

    }


    const publishTheQuestion = async () => {
        const confirmResult = await Swal.fire({
            title: 'Are you sure you want proceed?',
            text: "All temporary question will be made accessible to student who which to take the exam. Kindly cross your I's and dot Your T's 😀",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (confirmResult.isConfirmed) {
            try {
                const examid = assessment.uuid;
                const res = await API.post('publish_questions', {examid}, {headers})

                if (res.data === 'saved') {
                    successAlert('Great', 'The questions have been pulished and studens can now access them!')
                    window.location.reload()
                }
            } catch (e) {
                errorAlert('Oop', 'An Error occurred!')
            }
        }
    }
    const tablerows = questions.map((q) => {
        return (
            <tr key={q.id}>
                <td>
                    <a href={`/edit-question/${q.ref}`} className="btn btn-sm btn-outline-success">
                        <i className="fas fa-edit"></i>
                    </a>
                    {/*<button*/}
                    {/*    title="Toggle status"*/}
                    {/*    onClick={toggleEditMode}*/}
                    {/*    className="btn btn-sm btn-outline-warning"*/}
                    {/*>*/}
                    {/*    <i className="fas fa-exchange"></i>*/}
                    {/*</button>*/}
                </td>
                <td>
                    <div dangerouslySetInnerHTML={{__html: q.questionZone}}></div>
                </td>
                <td>
                    <span className="badge bg-info">{q.type}</span>
                </td>
                <td>
                    {q.isTemp ? (
                        <span className="badge bg-warning">Temporary</span>
                    ) : (
                        <span className="badge bg-success">Published</span>
                    )}
                </td>
                {/*<td>{q.points}</td>*/}
                <td className="text-end">{q.duration}</td>
            </tr>
        );
    });


    return (<div className='content'>
        <TopMeunSection personinfo={personinfo}/>
        <div className="container-fluid mt-3">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex mb-4 justify-content-between">
                                <h3><a href="/courses" className="dynmatxt"><i
                                    className="fas fa-arrow-left"></i></a> {assessment?.title} <small className="text-uppercase text-info">[{assessment?.assessment}]</small></h3>
                                <a className="btn btn-info" href="#">Refresh page</a>
                            </div>
                            <div className="row">
                                <FormComponent formData={formData} handleChange={handleChange} editMode={editMode}
                                               editorRef={editorRef}
                                               handleEditorChange={handleEditorChange} questionType={questionType}
                                               publishTheQuestion={publishTheQuestion}
                                               handleQuestionTypeChange={handleQuestionTypeChange}
                                               onsubmit={onsubmit}/>
                                <div id="questionTableList" className="col-sm-12 col-lg-5">
                                    <div className=" table-responsive">
                                        <table className="table table-border table-striped" id="questionTable">
                                            <thead>
                                            <tr>
                                                <th><i className="fas fa-ellipsis-h"></i></th>
                                                <th>Question</th>
                                                <th>Question Type</th>
                                                <th>Status</th>
                                                {/*<th>Points</th>*/}
                                                <th className="text-end">Duration
                                                    (<small>in seconds</small>)
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tablerows}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default AddQuestionContent;