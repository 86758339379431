export default [

    {
        id: 0,
        name: 'Courses',
        imagelink: "/dist/img/books.png",
        link: "/courses",
    },
    {
        id: 1,
        name: 'AI',
        imagelink: "/dist/img/robot.png",
        link: "/ai",
    },
    {
        id: 2,
        name: 'Updates',
        imagelink: "/dist/img/whats-new.png",
        link: "#",
    },
];