import React, {useEffect, useState} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const EditFormComponent = ({
                                      question,
                                      formData, setFormData, handleChange,
                                      editor, editorRef,
                                      handleEditorChange, publishTheQuestion,
                                      handleQuestionTypeChange,
                                      onsubmit,
                                  }) => {
    const [answers, setAnswers] = useState([null]);
    useEffect(() => {
        if (question) {

            if (question && editor) {
                editor.setData(question?.questionZone);
            }
        }
        setAnswers(question?.answers)
        setFormData(question)
    }, [editor]);

    const handleTextChange = (index, event) => {
        const {value} = event.target;
        setAnswers(prevAnswers => {
            const updated = [...prevAnswers];
            updated[index] = {...updated[index], answer: value};
            return updated;
        });
    };

    const handleRadioChange = (index) => {
        setAnswers(prevAnswers => {
            const updated = [...prevAnswers];
            updated.forEach((answer, i) => {
                answer.isanswer = (i === index);
            });
            return updated;
        });
    };
    return (
        <form onSubmit={onsubmit}
              className="exam-question-space border border-success pe-3 ps-3 pb-3 mb-4 col-sm-12 col-md-8 offset-md-2">
            <div className="row">
                <div className="col-md-12 pr-0 text-center w-100">
                    <div className="row">
                        <div className="col mt-3 text-left">
                            <label htmlFor="questionType">Question Type</label>
                            <select name="type" onChange={handleQuestionTypeChange} value={formData.type}
                                    className="form-control requiredField" id="questionType">
                                <option value="multiple">Multi choice</option>
                                <option value="single">Single Choice</option>
                            </select>
                        </div>
                        <div className="col mt-3 text-left">
                            <label
                                htmlFor="questionPoint">Question Point
                                <small className="text-danger">*</small></label>
                            <input type="number" className="form-control numberField"
                                   required value={formData.points} onChange={handleChange}
                                   max="100"
                                   placeholder="Question point?" id="questionPoint"
                                   name="points"/>
                        </div>
                        <div className="col mt-3 text-left">
                            <label
                                htmlFor="">Duration(<small>in seconds</small>)
                                <small
                                    className="text-danger">*</small></label>
                            <input type="number"
                                   className="form-control numberField requiredField"
                                   id="questionDuration" value={formData.duration} onChange={handleChange}
                                   placeholder="Duration (in seconds)"
                                   name="duration" required/>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <h4 className="fw-bold text-left">Question <span className="text-danger">*</span></h4>
                    <CKEditor editor={ClassicEditor} onChange={handleEditorChange}
                              onReady={(editor) => {
                                  editorRef.current = editor;
                              }}/>
                </div>
            </div>
            <hr/>
            {question.type === 'multiple' && (
                <>
                    <h4 className="fw-light">Enter Possible choices below</h4>
                    {answers && answers.length > 0 ? (
                        answers.map((answer, index) => (
                            <div className="row pt-2" key={index}>
                                <div className="col-md-1 pl-0 form-check text-center">
                                    <input
                                        type="radio"
                                        className=""
                                        checked={answer.isanswer}
                                        name="realAnswerQ1"
                                        value={index}
                                        onChange={() => handleRadioChange(index)}
                                    />
                                </div>
                                <div className="col-md-10 pl-0">
                                    <input
                                        type="text"
                                        required
                                        placeholder=""
                                        className={`${index} form-control requiredField`}
                                        name="answerQ1"
                                        value={answer.answer}
                                        onChange={(event) => handleTextChange(index, event)}
                                    />
                                </div>
                                <div className="col-md-1"><span className="text-danger">*</span></div>
                            </div>
                        ))
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-md-1 pl-0 form-check text-center">
                                    <input
                                        type="radio"
                                        className=""
                                        name="realAnswerQ1"
                                        value="0"
                                    />
                                </div>
                                <div className="col-md-10 pl-0">
                                    <input
                                        type="text"
                                        required
                                        placeholder=""
                                        className="1 form-control requiredField"
                                        name="answerQ1"
                                    />
                                </div>
                                <div className="col-md-1"><span className="text-danger">*</span></div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-md-1 pl-0 form-check text-center">
                                    <input
                                        type="radio"
                                        className=""
                                        name="realAnswerQ1"
                                        value="1"
                                    />
                                </div>
                                <div className="col-md-10 pl-0">
                                    <input
                                        type="text"
                                        required
                                        placeholder=""
                                        className="2 form-control requiredField"
                                        name="answerQ1"
                                    />
                                </div>
                                <div className="col-md-1"><span className="text-danger">*</span></div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-md-1 pl-0 form-check text-center">
                                    <input
                                        type="radio"
                                        className=""
                                        name="realAnswerQ1"
                                        value="2"
                                    />
                                </div>
                                <div className="col-md-10 pl-0">
                                    <input
                                        type="text"
                                        required
                                        placeholder=""
                                        className="3 form-control requiredField"
                                        name="answerQ1"
                                    />
                                </div>
                                <div className="col-md-1"><span className="text-danger">*</span></div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-md-1 pl-0 form-check text-center">
                                    <input
                                        type="radio"
                                        className=""
                                        name="realAnswerQ1"
                                        value="3"
                                    />
                                </div>
                                <div className="col-md-10 pl-0">
                                    <input
                                        type="text"
                                        required
                                        placeholder=""
                                        className="4 form-control requiredField"
                                        name="answerQ1"
                                    />
                                </div>
                                <div className="col-md-1 d-none d-sm-block">
                                    <span className="text-danger">*</span>
                                </div>
                            </div>
                        </>
                    )}

                </>
            )}
            <div className="d-flex mt-3">
                <button type="submit" className="btn btn-success btn-sm mx-auto" id="addquestion">
                    <i className="fa fa-check"></i> Save modifications
                </button>
                <button type="button" className="btn btn-info btn-sm mx-auto"
                        id="editquestion" onClick={publishTheQuestion}>
                    <i className="fa fa-plus-circle"></i> Publish questions
                </button>
            </div>
        </form>
    );
}

export default EditFormComponent;