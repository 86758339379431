import React from 'react';
import {Navigate} from 'react-router-dom';
import {Context} from "../../context/Contexts";

export const ProtectedRoute = ({children}) => {
    const {user} = React.useContext(Context)
    if (!user) {
        return <Navigate to="/"/>;
    }

    return children;
};
export default ProtectedRoute;