import axios from 'axios';

// Create a new instance of Axios
const api = axios.create({
    baseURL: process.env.REACT_APP_HOSST,
    // Add any other Axios configuration options you need
});

// Add an interceptor for response errors
//             console.log(Object.keys(localStorage.getItem("user")).length > 0);
api.interceptors.response.use(
    response => response,
    error => {
        const {response} = error;
        // Check if the error response status is 401 (Unauthorized) and handle the error accordingly
        if (response && response.data.message === 'Expired JWT Token') {
            // Redirect to logout URL or perform any other action you desire
            localStorage.clear();
            window.location.href = '/logout';
        }
        return Promise.reject(error);
    }
);

export default api;
