import setPageTitle from "../services/TitleService";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import React from "react";
import NBDetailContent from "../components/NotificationBoard/NBDetailContent";

export const NotificationBoardDetailPage = () => {
    const {id} = useParams();
    setPageTitle('Message board');
    const personinfo = useSelector(state => state.user.userInfo);
    return (
        <div className="d-flex position-relative">
            <LeftSide key={Math.random()} personinfo={personinfo}/>
            <NBDetailContent key={Math.random()} id={id} personinfo={personinfo}/>
        </div>
    )
}
export default NotificationBoardDetailPage;