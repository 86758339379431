import {useSelector} from "react-redux";
import React from "react";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import setPageTitle from "../../services/TitleService";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";
import EditQuestionContent from "../../components/Questions/EditQuestionContent";

export const AddQuestion = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    if(personinfo.personType !== 'Lecturer'){
        UnathorizedRedirect()
    }
    setPageTitle('Edit question');
    return (
        <div className="d-flex position-relative">
            <LeftSide key={Math.random()} personinfo={personinfo}/>
            <EditQuestionContent key={Math.random()} personinfo={personinfo}/>
        </div>
    )
}
export default AddQuestion;