import React from "react";
import Select from 'react-select'
import {Controller} from "react-hook-form";

export const BasicInfo = ({formStep, countries, control, register, errors, errorMessageRender}) => {

    const countriesArray = Object.values(countries);
    const countriesOptions = countriesArray.map((d) => (
        {value: d.id, label: `${d.flag} ${d.en}`}
    ));
    const preselectedValue = {value: 157, label: "🇳🇬 Nigeria"};
    const callCodeOptions = countriesArray.map((d, index) => (
        {value: d.id, label: `${d.flag} ${d.callcode}`}
    ));
    const preselectedCallcode = {value: 157, label: "🇳🇬 +234"};
    return (
        <div className={formStep !== 1 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">BASIC INFORMATION </h3>
            <p className="small text-secondary text-center">We just need some basic information to know you better</p>
            <p className="text-end dynmatxt">STEP: <small className="fw-bold">{formStep}/4</small></p>
            <div className="row">

                <div className="col-sm-12 col-md-12 mb-4">

                    <label htmlFor="lastname">What's your Surname? <span className="text-danger">*</span></label>

                    <input id="lastname" {...register("lastname", {
                        required: {
                            value: true,
                            message: 'Please include your surname'
                        }
                    })} className="form-control"
                           required="required" type="text" placeholder=""
                    />
                    {errors.lastname && errorMessageRender(errors.lastname.message)}
                </div>
                <div className="col-sm-12 col-md-12 mb-4">

                    <label htmlFor="firstname">What are your other names? <span className="text-danger">*</span></label>

                    <input id="firstname" {...register("firstname", {
                        required: {
                            value: true,
                            message: 'Please include your other name'
                        }
                    })} name="firstname" className="form-control"
                           required="required" type="text" placeholder=""
                           data-rule-required="true" data-msg-required=""
                           aria-required="true"/>
                    {errors.firstname && errorMessageRender(errors.firstname.message)}
                </div>

                <div className="col-sm-12 col-md-6 mb-4">

                    <label htmlFor="dateofbirth" className="active">What's the your date of birth? <span
                        className="text-danger">*</span></label>

                    <input id="dateofbirth" {...register("dateofbirth", {
                        required: {
                            value: true,
                            message: 'Please enter your date of birth.'
                        }
                    })} className="form-control" type="date" placeholder="mm/dd/yyyy" data-rule-required="true"
                    />
                    {errors.dateofbirth && errorMessageRender(errors.dateofbirth.message)}
                </div>
                <div className="col-sm-12 col-md-6 mb-4">

                    <label htmlFor="email">What's your E-mail Address? <span className="text-danger">*</span></label>

                    <input id="email" className="form-control" {...register("emailadress", {
                        required: {
                            value: true,
                            message: 'Please enter a valid email address.'
                        }
                    })} type="email"
                           placeholder=""
                           data-rule-required="true" data-msg-required=""
                           aria-required="true"/>
                    {errors.emailadress && errorMessageRender(errors.emailadress.message)}
                </div>
                <div className="col-sm-12 col-md-6 mb-4">

                    <label htmlFor="telephonenumber" className="active">What's your Telephone number? <span
                        className="text-danger">* <small>(No space, no + sign or point)</small></span></label>

                    <div className="input-group mb-3">
                            <span className="input-group-text p-0">

                                <Controller
                                    name="telephonecallcode"
                                    control={control}
                                    defaultValue={preselectedCallcode}
                                    render={({field}) => (
                                        <Select {...field} options={callCodeOptions}/>
                                    )}
                                />
                            </span>
                        <input type="text" name="telephonenumber" {...register("telephonenumber", {
                            required: {
                                value: true,
                                message: 'Please enter phone number.'
                            }
                        })} className="form-control numberField" placeholder="(without country code)"/>
                    </div>
                    {errors.telephonenumber && errorMessageRender(errors.telephonenumber.message)}
                </div>
                <div className="col-sm-12 col-md-6 mb-4">

                    <label htmlFor="whatsappnumber" className="active">What's your Whatsapp number? <span
                        className="text-danger">* <small>(No space, no + sign or point)</small></span></label>

                    <div className="input-group mb-3">
                            <span className="input-group-text p-0" id="basic-addon1">
                                <Controller
                                    name="whatsappcallcode" // Set the name for your form field
                                    control={control}
                                    defaultValue={preselectedCallcode} // Set the default value
                                    render={({field}) => (
                                        <Select {...field} options={callCodeOptions}
                                        />
                                    )}
                                />
                            </span>
                        <input type="text" {...register("whatsappnumber", {
                            required: {
                                value: true,
                                message: 'Please enter your whatsapp number.'
                            }
                        })} name="whatsappnumber" className="form-control numberField"
                               placeholder="(without country code)"/>
                    </div>
                    {errors.whatsappnumber && errorMessageRender(errors.whatsappnumber.message)}
                </div>
                <div className="col-sm-12 col-md-6 mb-4">

                    <label htmlFor="nationality">What's the your country of origin? <span
                        className="text-danger">*</span></label>
                    <Controller
                        name="country" // Set the name for your form field
                        control={control}
                        defaultValue={preselectedValue} // Set the default value
                        render={({field}) => (
                            <Select {...field} options={countriesOptions}/>
                        )}
                    />

                </div>
                <div className="col-sm-12 col-md-6 mb-4">

                    <label htmlFor="gender">What is your gender? <span className="text-danger">*</span></label>
                    <select className="form-control" {...register("gender", {
                        required: {
                            value: true,
                            message: 'Please select a gender'
                        }
                    })}
                            id="gender">
                        <option value=""></option>
                        <option value="1">Male</option>
                        <option value="0">Female</option>
                    </select>
                    {errors.gender && errorMessageRender(errors.gender.message)}

                </div>
                <div className="col-sm-12 col-md-12 mb-4">

                    <label htmlFor="adress">What is your current home Address? <span
                        className="text-danger">*</span></label>

                    <input id="adress" {...register("address", {
                        required: {
                            value: true,
                            message: 'Please enter a your address.'
                        }
                    })} className="form-control" name="address" required="required"
                           type="textarea"
                    />
                </div>
                {errors.address && errorMessageRender(errors.address.message)}
            </div>

        </div>
    )
}

export default BasicInfo;