import React from "react";

export const SummaryInfo = ({formStep, studentData, programs, level, admissionType, errors, errorMessageRender}) => {
    const programsArry = Object.values(programs);
    const programsObj = programsArry.find((obj) => obj.id === studentData.program)
    const admissionTypeArray = Object.values(admissionType);
    const admissionTypeObj = admissionTypeArray.find((obj) => obj.id === studentData.admissionType)
    const levelArray = Object.values(level);
    const levelArrayObj = levelArray.find(item => item.id === parseInt(studentData.level))
    const downloadFile = (fileDetails) => {
        if (!fileDetails) {
            // Handle the case where fileDetails is not available
            return;
        }

        // Create a download link for the file
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(fileDetails);
        downloadLink.download = fileDetails.name;
        downloadLink.textContent = `Download ${fileDetails.name}`;

        // Append the download link to the document body (or any other container element)
        document.body.appendChild(downloadLink);

        // Click the link to initiate the download
        downloadLink.click();

        // Remove the link from the document after download
        document.body.removeChild(downloadLink);
    };
    return (
        <div className={formStep !== 5 ? "d-none" : undefined}>
            <h3 className="primary-color text-center">REGISTRATION SUMMARY</h3>
            <p className="small text-secondary text-center">Here is the summary the data you provided</p>
            <div className="px-2">
                <h5 className="my-3 dynmatxt">Basic info</h5>
                <ul className="list-group">
                    <li className="list-group-item"><b>Surname:</b> {studentData?.lastname}</li>
                    <li className="list-group-item"><b>Other names:</b> {studentData?.firstname}</li>
                    <li className="list-group-item"><b>Date of birth:</b> {studentData?.dateofbirth}</li>
                    <li className="list-group-item"><b>E-mail :</b> {studentData?.emailadress}</li>
                    <li className="list-group-item"><b>Telephone
                        number:</b> {studentData?.telephonecallcode?.label}{studentData?.telephonenumber}</li>
                    <li className="list-group-item"><b>Whatsapp
                        number:</b> {studentData?.whatsappcallcode?.label}{studentData?.whatsappnumber}</li>
                    <li className="list-group-item"><b>Nationality:</b> {studentData?.country?.label}</li>
                    <li className="list-group-item"><b>Gender:</b> {studentData?.gender === '1' ? "Male" : "Female"}
                    </li>
                    <li className="list-group-item"><b>Address:</b> {studentData?.address}</li>
                </ul>
                <h5 className="my-3 dynmatxt">Academic info</h5>
                <ul className="list-group">
                    <li className="list-group-item"><b>Admission type:</b> {admissionTypeObj?.libelle}</li>
                    <li className="list-group-item"><b>Level:</b> {levelArrayObj?.title}</li>
                    <li className="list-group-item"><b>Program:</b> {programsObj?.libelle}</li>
                    <li className="list-group-item"><b>Most recent certificate:</b> {studentData?.certificate?.label}
                    </li>
                    <li className="list-group-item"><b>No of sitting:</b> {studentData?.noofsitting}</li>
                    <li className="list-group-item"><b>Department:</b> {studentData?.department?.label}</li>
                    <li className="list-group-item"><b>How you found the
                        school:</b> {studentData?.schoolDiscovery?.label}</li>
                </ul>
                <h5 className="my-3 dynmatxt">Guardian/Next of kin info</h5>
                <ul className="list-group">
                    <li className="list-group-item"><b>Fullname:</b> {studentData?.gfullname}</li>
                    <li className="list-group-item">
                        <b>Telephone:</b> {studentData?.gtelephonecallcode?.label}{studentData?.gnumber}</li>
                    <li className="list-group-item"><b>Email:</b> {studentData?.gemail}</li>
                    <li className="list-group-item"><b>Address:</b> {studentData?.gaddress}</li>
                    <li className="list-group-item"><b>Relation to prospect:</b> {studentData?.grelation}</li>
                </ul>
                <h5 className="my-3 dynmatxt">Files</h5>
                {studentData?.fileUploads && Object.keys(studentData?.fileUploads).length > 0 ? (
                    Object.keys(studentData?.fileUploads).map((fileKey) => (
                        studentData?.fileUploads[fileKey]?.name && (
                            <div className="d-flex" key={fileKey}>
                                <p className="dynmatxt">{studentData?.fileUploads[fileKey]?.name}</p>
                                &nbsp;
                                <a className="btn-sm dynmatxt" target="_blank" title="Download" onClick={() => downloadFile(studentData?.fileUploads[fileKey])}>
                                    <i className="fas fa-download"></i>
                                </a>
                            </div>
                        )
                    ))
                ) : (
                    <h6 className="fw-bold text-danger">No files uploaded!</h6>
                )}


            </div>
        </div>
    )
}
export default SummaryInfo;