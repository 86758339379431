import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import Content from "./Content";
import React from "react";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";

export const Assessments = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    setPageTitle('Exams & Tests');
    UnathorizedRedirect(personinfo, ['Lecturer','Student'])
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <Content personinfo={personinfo}/>
        </div>
    )
}
export default Assessments;