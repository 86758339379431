import React from "react";

export const AnsweredScriptLecturer = ({info, updateStudentPoint}) => {
    const getValueBasedOnDifference = (num1, num2) => {
        const percentageDifference = ((num2 - num1) / Math.abs(num2)) * 100;
        if (percentageDifference >= 0 && percentageDifference < 25) {
            return "bg-success";
        } else if (percentageDifference >= 25 && percentageDifference < 60) {
            return "bg-warning";
        } else if (percentageDifference > 60) {
            return "bg-danger";
        }
    };
    const getpercentagevalue = (point, percentage) => {
        return  Math.round(Math.abs((point * percentage) / 100));
    };
    const handleStudentPointChange = (newStudentPoint) => {
        updateStudentPoint(info.matricNo, info.qUuid, newStudentPoint);
    };

    const handleManualPointChange = (e) => {
        const newStudentPoint = Number(e.target.value);
        updateStudentPoint(info.matricNo, info.qUuid, newStudentPoint);
    };
    const [displayMode, setDisplayMode] = React.useState("buttonGroup");
    const toggleDisplayMode = () => {
        setDisplayMode((prevMode) => (prevMode === "buttonGroup" ? "inputField" : "buttonGroup"));
    };
    return (
        <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button
                        className={
                            info.qMarked
                                ? `accordion-button collapsed ${getValueBasedOnDifference(
                                    info.studentPoint,
                                    info.qPoint
                                )}`
                                : "accordion-button collapsed bg-secondary"
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${info.qUuid}`}
                        aria-expanded="false"
                        aria-controls={`${info.qUuid}`}
                    >
                        <div className="d-flex justify-content-between w-75">
              <span>
                Question: <b>{info.qRef}</b>
              </span>
                            <span>
                Student Point: <b>{Number(info.studentPoint)}</b>
              </span>
                            <span>
                Total question point: <b>{Number(info.qPoint)}</b>
              </span>
                        </div>
                    </button>
                </h2>
                <div
                    id={`${info.qUuid}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                >
                    <div className="accordion-body">

                        <div className="d-flex">
                            <div className="flex-grow-1" dangerouslySetInnerHTML={{__html: info.qTitle}}></div>
                            <div>
                                <p className="badge bg-info text-uppercase">{info.qType} question</p>
                                {!info.qMarked && <span className="badge bg-warning">Not marked</span>}
                            </div>
                        </div>
                        {info.qType === "multiple" && (
                            <ul>
                                {Object.keys(info.answers).map((a, index) => {
                                    const item = info.answers[a];
                                    return (
                                        <li key={index}>
                                            {info.studentAnswer === item.uuid && (item.isanswer ? (
                                                <i className="fas fa-check text-success"></i>
                                            ) : (
                                                <i className="fas fa-times text-danger"></i>
                                            ))}
                                            {item.answer}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}

                        {info.qType === "single" && (
                            <div>
                                <hr/>
                                <small>Student answer</small>
                                <div dangerouslySetInnerHTML={{__html: info.studentAnswer}}></div>
                            </div>
                        )}

                        <hr/>
                        {displayMode === "buttonGroup" && (
                            <div><p className="my-2"><small>Select score</small></p>
                                <div className="btn-group mx-auto w-100 mb-3" role="group" aria-label="Basic example">
                                    <button
                                        onClick={() => handleStudentPointChange(getpercentagevalue(info.qPoint, 25))}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Score {getpercentagevalue(info.qPoint, 25)}/{info.qPoint}
                                    </button>
                                    <button
                                        onClick={() => handleStudentPointChange(getpercentagevalue(info.qPoint, 50))}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Score {getpercentagevalue(info.qPoint, 50)}/{info.qPoint}
                                    </button>
                                    <button
                                        onClick={() => handleStudentPointChange(getpercentagevalue(info.qPoint, 75))}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Score {getpercentagevalue(info.qPoint, 75)}/{info.qPoint}
                                    </button>
                                    <button
                                        onClick={() => handleStudentPointChange(info.qPoint)}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Score {info.qPoint}/{info.qPoint}
                                    </button>
                                </div>
                            </div>
                        )}
                        {displayMode === "inputField" && (
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" id="floatingInput"
                                       value={info.studentPoint || ""}
                                       onChange={handleManualPointChange}
                                       placeholder="name@example.com"/>
                                <label htmlFor="floatingInput">Enter score manually</label>
                            </div>
                        )}
                        <button className="btn btn-sm btn-outline-secondary" onClick={toggleDisplayMode}>Toggle score input method</button>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};
export default AnsweredScriptLecturer;
