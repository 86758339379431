import studentshort from "./studentshort";
import lecturershortcut from "./lecturershortcut";
import agentshortcut from "./agentshortcut";
import ShortCutsLayout from "./ShortCutsLayout";
import {leftSidetoggled} from "../../redux/userRedux";
import {useDispatch} from "react-redux";

export const TopMeunSection = ({personinfo}) => {
    let menusection = <h2><b className="dynmatxt">Good day,</b> <span
        className="text-secondary">{personinfo?.firstname ? personinfo.firstname : ''}</span></h2>;
    if (personinfo?.pagetitle) {
        menusection = <h2><b className="dynmatxt">{personinfo?.pagetitle}</b></h2>
    }
    let shortcuts = [];
    if (personinfo?.personType === 'Student') {
        shortcuts = studentshort
    } else if (personinfo?.personType === 'Lecturer') {
        shortcuts = lecturershortcut
    }
    else if (personinfo?.personType === 'Agent') {
        shortcuts = agentshortcut
    }
    const shortcutsElement = shortcuts.map(item => {
        return <ShortCutsLayout
            key={item.id}
            {...item}
        />
    })
    const dispatch = useDispatch();
    const handleLeftSideToggle = () => {
        dispatch(leftSidetoggled(true))
    };
    return (
        <div className="topbar">
            <div className="d-flex">
                <div className="menutoggler" id="menutoggler"><h2><i onClick={handleLeftSideToggle} className="las la-bars"></i></h2></div>
                {menusection}
            </div>
            <div className="projectswicther-top">
                <ul className="nav nav-pills projectswicther">
                    {shortcutsElement}
                </ul>
            </div>
        </div>
    )
}
export default TopMeunSection;