import React from "react";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import Content from "../components/Course/Content";
import {useSelector} from "react-redux";
import setPageTitle from "../services/TitleService";
import UnathorizedRedirect from "../services/UnathorizedRedirect";

export const Course = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    setPageTitle('Course');
    UnathorizedRedirect(personinfo, ['Student','Lecturer'])
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <Content personinfo={personinfo}/>
        </div>
    )
}
export default Course;