import React from "react";
import TopMeunSection from "../Blocks/TopMeunSection";
import StudentInfo from "./StudentInfo";
import LecturerInfo from "./LecturerInfo";
import {Context} from "../../context/Contexts";
import API from "../../api";
import WalletCard from "./WalletCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClickToCopy from "../ClickToCopy";
import TransactionTable from "./TransactionTable";
import NotificationBoard from "./NotificationBoard";
import AffiliateDashboardSection from "../Affiliates/AffiliateDashboardSection";
import AgentInfo from "./AgentInfo";

export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const [userwallets, setUserwallets] = React.useState([]);
    const walletSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const headers = {
        Authorization: `Bearer ${user?.token}`,
    };
    React.useEffect(() => {
        API.post('wallet_info', {}, {headers})
            .then(res => setUserwallets(res.data))
        ;
    }, []);

    const walletElement = (
        userwallets.length > 1 ?
            <Slider {...walletSettings}>
                {userwallets.map((item, index) => (
                    <WalletCard key={index} {...item} />
                ))}
            </Slider>
            :
            userwallets.map((item, index) => (
                <WalletCard key={index} {...item} />
            ))
    );
    const shareLink = window.location.origin+'/application-form/sponsor/'+personinfo?.sharecode;
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-6 col-lg-6 mt-4 mb-3">
                        {personinfo?.personType === 'Student' &&
                            <StudentInfo personinfo={personinfo} headers={headers}/>}
                        {personinfo?.personType === 'Lecturer' &&
                            <LecturerInfo personinfo={personinfo} headers={headers}/>}
                        {personinfo?.personType === 'Agent' &&
                            <AgentInfo personinfo={personinfo} headers={headers}/>}
                        {userwallets.length>1 ?walletElement: (<div className="text-info fw-bold p-2">You don't have any wallet for now but once a transaction is made between you and someelse the wallet will created and credited automatically</div>)}
                    </div>
                    <NotificationBoard headers={headers}/>
                    <div className="col-sm-12  mt-4 mb-3">
                        <div className="row">
                            <div className="col-sm-12 col-lg-4">
                                <AffiliateDashboardSection/>
                            </div>
                            <div className="col-sm-12 col-lg-8">
                                <div className="card rewv4s mb-4">
                                    <div className="card-body d-flex sharesecetion justify-content-between">

                                        <div className="float-end">
                                            <h3 className="fw-bold ">Invite a friend and get paid</h3>
                                            <h5 className="text-success copyusercode"
                                                data-value={shareLink}
                                            >

                                                <i className="las la-clipboard text-success"></i>
                                                <ClickToCopy data={shareLink}/>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card table-responsive">
                            <div className="card-body">
                                <h3 className="card-header">3 Recent Transactions</h3>
                                <TransactionTable headers={headers}/>
                                <a href="/transaction-history" className="btn btn-success w-100 btn-sm">
                                    View
                                    more&nbsp;
                                    <i className="fas fa-arrow-alt-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}
export default Content;