import "../assets/css/global.scss";
import "../assets/css/main.scss";
import "../assets/css/responsive.scss";
import React from "react";
import Content from "../components/Dashboard/Content";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import setPageTitle from '../services/TitleService';
import {useDispatch, useSelector} from "react-redux";
import API from "../api";
import {updateuserinfo} from "../redux/userRedux";
import {Context} from "../context/Contexts";


export const Dashboard = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    const dispatch = useDispatch();
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    React.useEffect(() => {
        const req = async (e) => {
            setPageTitle('Dashboard');
            if (personinfo === null) {
                try {
                    const res = await API.post('getUserInfo', {}, {headers})
                    // console.log(res.data);
                    dispatch(updateuserinfo(res.data))
                } catch (e) {

                }
            }
        }
        req()

    }, []);
    return (
        <div className="d-flex position-relative">
            <LeftSide  key={Math.random()} personinfo={personinfo}/>
            <Content key={Math.random()} personinfo={personinfo}/>
        </div>
    )
}
export default Dashboard;