import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import API from "../../api";
import CourseTable from "./CourseTable";
import DataTable from "react-data-table-component";
import successAlert from "../../services/AlertS/sucess";
import errorAlert from "../../services/AlertS/error";

const CourseRegisterModal = ({headers, modalShow, setModalShow, apptheme,handleCourselistchange}) => {
    const [courses, setCourses] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await API.post('get_courses', {}, {headers});
                setCourses(res.data);
            } catch (error) {
                // Handle error
            }
        };
        fetchData();
    }, []);

    const [searchText, setSearchText] = useState('');
    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredData = courses.filter((row) =>
        row.title.toLowerCase().includes(searchText.toLowerCase()) ||
        row.code.toLowerCase().includes(searchText.toLowerCase())
    );

    const ExpandedComponent = ({data}) => (
        <div>
            <p>Title: <b>{data.title}</b></p>
            <p>Code: <b>{data.code}</b></p>
            <p>Unit: <b>{data.units}</b></p>
        </div>
    );
    const [selectedRows, setSelectedRows] = useState([]);

    const handleCloseModal=()=>{
        setModalShow(false)
    }

    const handleRowSelected = (rows) => {
        const selectedIds = rows.selectedRows.map((row) => row.id);
        setSelectedRows(selectedIds);
        // handleCloseModal()
    };

    const handleRegisterCourseBtn = (rows) => {
        const req = async (e) => {

            try {
                const res = await API.post('student_register_course', {selectedRows}, {headers})
                handleCourselistchange();
                successAlert('Operation successful','Course registration successful !');
            } catch (e) {
                errorAlert('Operation un-successful','An error occurred during course registration !')
            }
        }
        req()
    };
    return (
        <Modal show={modalShow} onHide={handleCloseModal} size="lg">
            <Modal.Header>
                <Modal.Title>
                    <div className="d-flex w-100">
                        <span>All courses</span>
                        {selectedRows.length >= 1 && <button onClick={handleRegisterCourseBtn} className="btn btn-sm btn-info mx-3 text-uppercase">Register {selectedRows.length} course(s)</button>}

                    </div>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <input
                    type="search"
                    className="form-control mb-3"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search by title or code..."
                />
                <DataTable
                    responsive
                    striped
                    onSelectedRowsChange={handleRowSelected}
                    theme={apptheme ? "dark" : undefined}
                    direction="auto"
                    subHeaderAlign="right"
                    subHeaderWrap
                    selectableRows
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    columns={CourseTable()}
                    data={filteredData}
                    pagination
                    persistTableHead
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                {/* Add additional buttons or actions as needed */}
            </Modal.Footer>
        </Modal>
    );
};

export default CourseRegisterModal;
