import React from "react";
import {Context} from "../../context/Contexts";
import TopMeunSection from "../Blocks/TopMeunSection";
import MessageCard from "./MessageCard";

export const Content = ({personinfo}) => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <MessageCard personinfo={personinfo}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Content;