import {useSelector} from "react-redux";
import setPageTitle from "../services/TitleService";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import React from "react";
import {TopupWalletContent} from "../components/TopupWallet/TopupWalletContent";

export const TopupWallet = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('Topup');

	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<TopupWalletContent personinfo={personinfo}/>
		</div>
	)
}
export default TopupWallet;