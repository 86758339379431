import {useSelector} from "react-redux";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import React from "react";
import TopMeunSection from "../../components/Blocks/TopMeunSection";

export const NotFound = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    return (
        <div className="d-flex position-relative">
            {personinfo && <LeftSide key={Math.random()} personinfo={personinfo}/>}
            <div className='content'>
                {personinfo && <TopMeunSection personinfo={personinfo}/>}
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <img className="d-block mx-auto mb-5" src="/dist/img/lost_re_xqjt.svg" alt="NOT FOUNF IMAGE" style={{ height: '300px' }}/>
                                    <h3 className="text-center">Page not found or system error</h3>
                                    <div className="text-center my-3">
                                        <a href="/dashboard" className="btn btn-primary"> Go to
                                            Dashboard</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NotFound;