import Swal from 'sweetalert2';

export default function successAlert(title, message) {
    // Display the title and message using SweetAlert
    Swal.fire({
        title: title,
        text: message,
        icon: 'success',
        timer: 5000, // Set the timer for auto-close
        showConfirmButton: false
    });
}