import StudentInfo from "../Dashboard/StudentInfo";
import LecturerInfo from "../Dashboard/LecturerInfo";
import React from "react";
import API from "../../api";
import warningAlert from "../../services/AlertS/warning";
import successAlert from "../../services/AlertS/sucess";
import errorAlert from "../../services/AlertS/error";
import AgentInfo from "../Dashboard/AgentInfo";

export const UserProfile = ({headers, personinfo, token}) => {
    const [personfileTypes, setPersonfileTypes] = React.useState([]);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [updateUploadables, setUpdateUploadables] = React.useState('');


    React.useEffect(() => {

        const req = async (e) => {

            try {
                const res = await API.post('get_file_types', {}, {headers});
                setPersonfileTypes(res.data)
            } catch (e) {

            }
        }
        req()

    }, [updateUploadables]);

    const handleFileUpload = (filetype, event) => {
        const file = event.target.files[0];
        let maxSizeFormatted = (String(filetype.maxSize).length > 6) ? String(filetype.maxSize).slice(0, 1) + " MB" :
            String(filetype.maxSize).slice(0, 1) + " KB";
        if (file.size > Number(filetype.maxSize)) {
            warningAlert("File upload error", "Max file size upload is " + maxSizeFormatted);
            event.target.value=""
            return false;
        }

        const fileData = new FormData();
        fileData.append("file", file);

        setUploadFiles((prevState) => ({
            ...prevState,
            [filetype.uuid]: fileData.get("file"), // Update the file data for the corresponding uuid
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const req = async (e) => {

            try {
                const res = await API.post('upload_file_types', uploadFiles, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                if (res.data === 'saved') {
                    setUpdateUploadables(Math.random());
                    successAlert('Nice one', 'The upload of your files was successful!')
                    setUploadFiles({});
                }

            } catch (e) {
                errorAlert('Upload error', e.response.data)
            }
        }
        req()
    }
    const baseURL = API.defaults.baseURL.slice(0, -4);

    const filetypeOutPut = personfileTypes.map((filetype, index) => {
        let fileformat;
        if (filetype.fileFormat === 'images_only') fileformat = '.png,.jpeg,.jpg,.webp';
        if (filetype.fileFormat === 'docs_only') fileformat = '.pdf,.pptx,.doc,.docx';
        if (filetype.fileFormat === 'docs_images_only') fileformat = '.pdf,.pptx,.doc,.docx,.png,.jpeg,.jpg,.webp';
        return (
            <div className="col-sm-12 col-lg-6">
                {filetype.uploaded && filetype.isModifiableByUser === false && (
                    <div className="mb-3">
                        <p className="text-center ">{filetype.libelle}</p>
                        <h4 className="text-center mt-3">
                            <a
                                className="text-decoration-none dynmatxt"
                                href={baseURL + filetype.location}
                                target="_blank"
                                download={true}
                            >
                                Download <i className="fas fa-download"></i>
                            </a>
                        </h4>
                    </div>
                ) }
                {filetype.uploaded && filetype.isModifiableByUser === true && (
                    <div className="mb-3">
                        <h4 className="text-center mt-3">
                            <a
                                className="text-decoration-none dynmatxt"
                                href={baseURL + filetype.location}
                                target="_blank"
                                download={true}
                            >
                                Download <i className="fas fa-download"></i>
                            </a>
                        </h4>
                        <label htmlFor={filetype.uuid} className="form-label">
                            {filetype.libelle}
                        </label>
                        <input
                            onChange={(event) => handleFileUpload(filetype, event)}
                            className="form-control"
                            type="file"
                            required={filetype.isRequired}
                            id={filetype.uuid}
                            accept={fileformat}
                        />

                    </div>
                )}
                {filetype.uploaded === false && (
                    <div className="mb-3">
                        <label htmlFor={filetype.uuid} className="form-label">
                            {filetype.libelle}
                        </label>
                        <input
                            onChange={(event) => handleFileUpload(filetype, event)}
                            className="form-control"
                            type="file"
                            required={filetype.isRequired}
                            id={filetype.uuid}
                            accept={fileformat}
                        />
                    </div>
                )}
            </div>
        );
    });


    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-lg-5">
                    <div className="card">
                        <div className="card-body">
                            {personinfo?.personType === 'Student' &&
                                <StudentInfo personinfo={personinfo} headers={headers}/>}
                            {personinfo?.personType === 'Lecturer' &&
                                <LecturerInfo personinfo={personinfo} headers={headers}/>}
                            {personinfo?.personType === 'Agent' &&
                                <AgentInfo personinfo={personinfo} headers={headers}/>}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-7">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit} className="row">
                                {filetypeOutPut}
                                <button className="btn btn-success mt-3">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserProfile;