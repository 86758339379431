import React, {useEffect, useState} from "react";
import "./messageCard.scss";
import CardComponent from "./CardComponent";
import {useLocation} from "react-router-dom";
import warningAlert from "../../services/AlertS/warning";
import API from "../../api";
import {Context} from "../../context/Contexts";
import errorAlert from "../../services/AlertS/error";

const MessageCard = ({personinfo}) => {
    const location = useLocation();
    const path = location.pathname.split("/")[1];

    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [conversationInputPopup, setConversationInputPopup] = useState(false);
    const [isNewConversation, setIsNewConversation] = useState(false);
    const [title, setTitle] = useState("");
    const [conversationLists, setConversationLists] = useState([]);
    const [responseData, setResponseData] = useState("");
    const [isMessageSent, setIsMessageSent] = useState(false);
    const [activeThread, setActiveThread] = useState({ref: '', title: ''});
    const [pending, setPending] = React.useState(false);
    const [chatLists, setchatLists] = useState([]);
    const [conversationHistory, setConversationHistory] = useState([]);

    const data = {
        isAI: input ? false : true,
        conversationRefernce: activeThread.ref,
        content: input ? input : responseData,
    };
    // handle conversation history
    const reverse = [...chatLists].reverse();

    useEffect(() => {
        setConversationHistory(
            reverse.map((item, index) => {
                return {
                    role: item?.isHumaneSender ? "system" : "user",
                    content: item.content,
                };
            })
        );
    }, [path, isMessageSent]);
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user?.token}`,
    };
    const sendAIMessage = async (message) => {
        setIsLoading(true);
        try {
            const response = await fetch(
                "https://api.openai.com/v1/chat/completions",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
                    },
                    body: JSON.stringify({
                        model: "gpt-3.5-turbo",
                        messages: conversationHistory.concat([
                            { role: "system", content: "When I ask for something, you will reply explain as though you are are talking to a 10 year old with examples before you give the correct response." },
                            { role: "user", content: message },
                        ]),
                    }),
                }
            );

            setIsLoading(false);

            const data = await response.json();
            setResponseData(data.choices[0].message.content);
        } catch (error) {
        }
    };

    const sendServerMessage = async () => {
        try {
            await API.post(
                "save_chat",
                data, {headers}
            );
            setIsMessageSent(!isMessageSent);
        } catch (error) {
        }
    };

    useEffect(() => {
        const sendMessage = async () => {
            await API.post(
                "save_chat",
                data, {headers}
            );
            setIsMessageSent(!isMessageSent);
        };
        responseData && sendMessage();
    }, [responseData]);

    const handleClick = (e) => {
        e.preventDefault();
        if (input !== '') {
            sendAIMessage(input);
            sendServerMessage();
            setInput("");
        } else {
            warningAlert('No message entered', "Kindly enter a message or subject for me to respond")
        }

    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (input !== '') {
                sendAIMessage(input);
                sendServerMessage();
                setInput("");
            } else {
                warningAlert('No message entered', "Kindly enter a message or subject for me to respond")
            }
        }
    };

    const handleIsNewConversation = () => {
        setConversationInputPopup(!conversationInputPopup);
    };

    const handleNewConversation = async (e) => {
        try {
            await API.post("new_conversation", {title}, {headers});
            setConversationInputPopup(false);
            setIsNewConversation(!isNewConversation);
            setTitle("");
            setPending(true)
            // setOpenMenu(false);
        } catch (error) {
            errorAlert('Oops','An error occurred !')
        }
    };

    const getConversationLists = async () => {
        try {
            const res = await API.post(
                "get_conversations", {},{headers}
            );
            setConversationLists(res.data);
            setPending(false)

        } catch (error) {
            // console.log(error.response.data);
        }
    };

    useEffect(() => {
        let unsubscribe = false;
        if (!unsubscribe) {
            getConversationLists();
        }
        return () => {
            unsubscribe = true;
        };
    }, [isNewConversation]);

    const getChatLists = async (reference) => {
        try {
            const response = await API.post(
                "get_chats",
                {conversation: activeThread.ref, page: 1}, {headers}
            );
            setchatLists(response.data);
            // setchatListsLength(response?.data?.length);
        } catch (error) {
            // console.log(error.response.data);
        }
    };

    useEffect(() => {
        let unsubsribe = false;
        if (!unsubsribe) {
            if (path) {
                getChatLists();
            }
        }
        return () => {
            unsubsribe = true;
        };
    }, [isMessageSent, path]);

    return (
        <div className="messageCard">
            <div
                className={
                    "conversationInputPopup " + (conversationInputPopup && "active")
                }
            >
                <input
                    type="text"
                    placeholder="Conversation title"
                    onChange={(e) => setTitle(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button className="btn-primary" onClick={handleNewConversation}>Create</button>
            </div>

            {/*<div className="menu">*/}
            {/*    <i className="fas fa-bars" onClick={() => setOpenMenu(!openMenu)}></i>*/}
            {/*</div>*/}

            <CardComponent personinfo={personinfo}
                           handleIsNewConversation={handleIsNewConversation}
                           conversationLists={conversationLists}
                           setConversationInputPopup={setConversationInputPopup}
                           isMessageSent={isMessageSent}
                           activeThread={activeThread}
                           headers={headers}
                           pending={pending}
                           setActiveThread={setActiveThread}
            />

            {activeThread.ref !=='' && (
                <div className="input">
                    <form className="row" onSubmit={handleClick}>
                        <div className="col">
                            <div className="input-group mb-3">
                                <div className="form-floating flex-grow-1">
                                    <textarea className="pt-1 h-auto form-control" placeholder="Leave a comment here"
                                              id="floatingTextarea" value={input}
                                              onChange={(e) => setInput(e.target.value)}
                                              onKeyDown={handleKeyDown}></textarea>
                                    {/*<label htmlFor="floatingTextarea"></label>*/}
                                </div>
                                {/*<span >*/}
                                <div className="input-group-text">
                                    <button disabled={isLoading || !input}>
                                        {isLoading ? (
                                            <i className="fas fa-circle-notch fa-spin"></i>
                                        ) : (
                                            <i className="fas fa-paper-plane"></i>
                                        )}
                                    </button>
                                </div>
                                {/*</span>*/}

                            </div>


                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default MessageCard;
