import {useSelector} from "react-redux";
import UnathorizedRedirect from "../services/UnathorizedRedirect";
import setPageTitle from "../services/TitleService";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import React from "react";
import Content from "../components/Scoreboard/Content";

export const Scoreboard = () => {
    const personinfo = useSelector(state => state.user.userInfo);
    UnathorizedRedirect(personinfo, 'Student')
    setPageTitle('Score-board');
    return (
        <div className="d-flex position-relative">
            <LeftSide personinfo={personinfo}/>
            <Content personinfo={personinfo}/>
        </div>
    )
}
export default Scoreboard;