import errorAlert from "../services/AlertS/error";
import successAlert from "../services/AlertS/sucess";

export const ClickToCopy = ({data}) => {

    const handleClick = () => {
        navigator.clipboard.writeText(data).then(
            () => {
                successAlert('Copied!', 'The item has been copied to the clipboard.')
            })
            .catch((error) => {
                errorAlert('Oops...', 'An error occurred while copying the item.')
            });
    };

    return <span className="click-to-copy" onClick={handleClick}>{data}</span>;
};
export default ClickToCopy;