import React, {useEffect, useRef, useState} from "react";
import SideComponents from "./SideComponents";
import moment from "moment";
import "moment-timezone";

import API from "../../api";

const CardComponent = ({
                           personinfo,
                           handleIsNewConversation,
                           conversationLists,
                           setConversationInputPopup,
                           isMessageSent,
                           activeThread,
                           headers,
                           pending,
                           setActiveThread,
                       }) => {
    const listRef = useRef(null);
    const baseURL = API.defaults.baseURL.slice(0, -4);

    const [chatLists, setchatLists] = useState([]);
    const [chatListsLength, setchatListsLength] = useState([]);
    const [page, setPage] = useState(1);

    // Scrolling effect
    const chatZoneRef = useRef(null);
    const scrollToBottom = () => {
        chatZoneRef.current.scrollTo({
            top: chatZoneRef.current.scrollHeight,
            behavior: 'smooth',
        });
    };

    const scrollToTop = () => {
        chatZoneRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        // Scroll to the bottom after a small delay
        const scrollTimeout = setTimeout(() => {
            listRef?.current?.scrollIntoView({behavior: "smooth", block: "end"});
        }, 1000);

        // Clear the timeout on component unmount
        return () => clearTimeout(scrollTimeout);
    }, []);

    const getChatLists = async (reference) => {
        try {
            const response = await API.post(
                "get_chats",
                {conversation: activeThread.ref, page: 1}, {headers}
            );
            setchatLists(response.data);
            setchatListsLength(response?.data?.length);
        } catch (error) {
            // console.log(error.response.data);
        }
    };
    const reverse = [...chatLists].reverse();

    useEffect(() => {
        activeThread.ref && getChatLists();
        scrollToBottom();
    }, [isMessageSent]);

    const handleLoadMore = async () => {
        setPage((prevPage) => prevPage + 1); // Use functional form of setState
        try {
            const response = await API.post(
                "get_chats",
                {conversation: activeThread.ref, page: page + 1} // Use updated page value here
            );

            const newChatLists = [...chatLists, ...response.data];
            // Update the state with the newChatLists array
            setchatLists(newChatLists);
            setchatListsLength(response?.data?.length);
        } catch (error) {
            // Handle error
        }
    };
    const manipDate = (OrginalDate) => {
        const date = moment.parseZone(OrginalDate);
        const now = moment(); // Current date and time
        const diffInMinutes = now.diff(date, 'minutes');
        const diffInHours = now.diff(date, 'hours');

        let formattedDate;

        if (diffInMinutes < 1) {
            formattedDate = 'Just now';
        } else if (diffInMinutes < 60) {
            formattedDate = `${diffInMinutes} minutes ago`;
        } else if (diffInHours < 12) {
            formattedDate = `${diffInHours} hours ago`;
        } else {
            formattedDate = date.format('YYYY-MM-DD HH:mm:ss');
        }
        return formattedDate;
    }
    return (
        <div className="wrapper">
            <div className="left" onClick={() => setConversationInputPopup(false)}>

                <div className={"chatZone " + (chatLists.length === 0 && "mt-4")} ref={chatZoneRef}>
                    {chatListsLength >= 10 && (
                        <div className="loadmore">
                            <button onClick={handleLoadMore}>Load More</button>
                        </div>
                    )}
                    {chatLists.length === 0 && (
                        <div className="not">
                            <h3>
                                Start a new thread or join existing thread by clicking
                                on the thread dropdown list in the navbar
                            </h3>
                        </div>
                    )}

                    {reverse?.map((item, index) => {
                        const formattedContent = item.content.split('\n').map((line, index) => {
                            if (line.startsWith('- ')) {
                                // Treat line as a list item
                                return <li key={index}>{line.substring(2)}</li>;
                            } else {
                                // Treat line as a paragraph
                                return <p key={index} style={{marginBottom: '20px'}}>{line}</p>;
                            }
                        });

                        return (

                            <div key={item.id} className={item.isHumaneSender ? "receiverCard" : "senderCard"}>
                                <div className="img">
                                    {!item.isHumaneSender ? (
                                        personinfo.profileImgCode ? (
                                            <img src={`${baseURL}${personinfo.profileImgCode}`} alt="ProfileImage"/>
                                        ) : (
                                            <i className="fas fa-user-circle"></i>
                                        )
                                    ) : (
                                        <i className="fas fa-robot text-primary"></i>
                                    )}

                                </div>
                                <div className="messageCon">
                                    <div className="details">
                                        {/* <p>John Doe</p> */}
                                        <span>{manipDate(item.createdDate)}</span>
                                    </div>
                                    {/* <div className="message"><prev>{item.content}</prev></div> */}
                                    <div className="message">{formattedContent}</div>
                                </div>
                            </div>

                        );
                    })}
                    {
                        chatLists.length !== 0 && (<>
                            <button className="scrollButton scrollButtonEnd" onClick={scrollToBottom}>
                                <i className="fas fa-arrow-down"></i>
                            </button>

                            <button className="scrollButton scrollButtonTop" onClick={scrollToTop}>
                                <i className="fas fa-arrow-up"></i>
                            </button>
                        </>)
                    }
                </div>

            </div>

            <SideComponents
                handleIsNewConversation={handleIsNewConversation}
                conversationLists={conversationLists}
                setchatLists={setchatLists}
                chatZoneRef={chatZoneRef}
                headers={headers}
                pending={pending}
                activeThread={activeThread}
                setActiveThread={setActiveThread}
                setchatListsLength={setchatListsLength}
            />
        </div>
    );
};

export default CardComponent;
