import ProfileArea from "./ProfileArea";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {leftSidetoggled, updateUserTheme} from "../../../redux/userRedux";
import NotificationCounter from "./NotificationCounter";

export const ProfileSection = () => {
    const dispatch = useDispatch();
    const personinfo = useSelector(state => state.user.userInfo);
    const theme = useSelector(state => state.user.userTheme);
    if (theme) {
        document.body.classList.add('dark-theme');
    } else {
        document.body.classList.remove('dark-theme');
    }
    const handleThemeToggle = () => {
        dispatch(updateUserTheme(!theme))
    };

    const handleLeftSideToggle = () => {
        dispatch(leftSidetoggled(false))
    };
    React.useEffect(() => {
        handleLeftSideToggle()
    }, []);
    const profileArea = personinfo && <ProfileArea key={personinfo.id} {...personinfo}/>

    return (
        <div className="profile-section">

            {profileArea && profileArea}
            <div className="d-flex justify-content-center mt-4 mb-5">
                <h4 className="me-3 closemenu" onClick={handleLeftSideToggle}><i
                    className="las la-times-circle la-2x text-white "></i></h4>
                <h4 className="me-3 switchTheme" onClick={handleThemeToggle}>
                    <i className="las la-sun la-1x"></i>

                </h4>
                <NotificationCounter/>
                <h4 className="me-3"><a href="/my-profile" className="text-white"><i className="las la-cog"></i></a>
                </h4>
                <h4 className="me-3"><a href="/logout" className="fw-bolder text-danger fw-bolde"><i
                    className="las la-power-off"></i></a></h4>

            </div>
        </div>
    )
}
export default ProfileSection;