import React, {useEffect, useState} from "react";
import {Context} from "../../../context/Contexts";
import API from "../../../api";
import errorAlert from "../../../services/AlertS/error";
import {useSelector} from "react-redux";

export const NotificationCounter = () => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [notificationsCount, setNotificationsCount] = useState(0);
    const countnotification = useSelector(state => state.user.reloadNotifiCationCounter);
    useEffect(() => {
        const req = async (e) => {

            try {
                const res = await API.post('count_user_notification', {}, {headers})
                setNotificationsCount(res.data);
            } catch (e) {
                errorAlert('Oop', 'Error while getting data!')
            }
        }
        req()

    }, [countnotification]);
    return (
        <h4 className="me-3 position-relative"><a href="/my-notifications" className="text-white"><i
            className="fas fa-bell"></i>
            {notificationsCount > 0 && (<small>
               <span
                   className="position-absolute notification-bellcounter top-0 start-100 translate-middle badge rounded-pill bg-danger">{notificationsCount}<span
                   className="visually-hidden">unread messages</span>
                </span>
            </small>)}
        </a>
        </h4>
    )
}
export default NotificationCounter