import React from "react";

export const MarkScriptTable = () => {

    const formattedDate = (dateString) => {
        return (new Date(dateString).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            })
        )
    }
    return [
        {
            cell: (row) => (
                // Render buttons within a cell
                <div>
                    <a
                        href={`mark-scripts/${row.uuid}`}
                        className="btn btn-sm btn-primary"
                    >
                        MARK IT
                    </a>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },

        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Assessment Type',
            selector: row => <span className="text-uppercase">{row.assessmentType}</span>,
            sortable: true,
        },
        {
            name: 'Attempted date',
            selector: row => row.created && formattedDate(row.created),
            sortable: true,
        }

    ];

}
