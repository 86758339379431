import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import React from "react";
import MarkingScriptContent from "./MarkingScriptContent";
import UnathorizedRedirect from "../../services/UnathorizedRedirect";

export const MarkingScript = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('Mark Scripts');
	UnathorizedRedirect(personinfo, 'Lecturer');
	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<MarkingScriptContent personinfo={personinfo}/>
		</div>
	)
}

export default MarkingScript;