import React from "react";
import API from "../../api";
import errorAlert from "../../services/AlertS/error";

const SideComponents = ({
                            handleIsNewConversation,
                            conversationLists,
                            setchatLists, headers,
                            setchatListsLength,pending,
                            chatZoneRef, activeThread, setActiveThread
                        }) => {


    const getThreadChats = async (thread) => {
        try {
            const response = await API.post(
                "get_chats",
                {conversation: thread.reference, page: 1}, {headers}
            );
            setchatLists(response.data);
            setchatListsLength(response?.data?.length);
            setActiveThread({ref: thread.reference, title: thread.title})
            chatZoneRef.current.scrollTo({
                top: chatZoneRef.current.scrollHeight,
                behavior: 'smooth',
            });

        } catch (error) {
            errorAlert("Oops something went wrong", '')
        }
    };
    return (
        <div className="right rounded-3">
            <div className="card w-100">
                <div className="card-body bg-primary-lgth rounded-3 d-flex justify-content-between">
                    <div className="btn-group">
                        <button type="button" className="btn btn-secondary">
                            {activeThread.title !== '' ? (
                                <span className="fw-bold mb-0 text-uppercase">{activeThread.title}</span>
                            ) : (
                                'Threads'
                            )}
                            {pending && (
                                <i className="fas fa-spinner fa-spin-pulse"></i>
                            )}
                        </button>

                        <button disabled={conversationLists.length === 0 && true} type="button"
                                className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-dark">
                            {Array.isArray(conversationLists) && conversationLists.map((item, index) => (
                                <li key={index}>
                                    <button onClick={() => getThreadChats(item)}
                                            className="dropdown-item text-uppercase">
                                        {item.title}
                                    </button>
                                </li>
                            ))}

                        </ul>
                    </div>

                    <button className="btn btn-sm btn-secondary" onClick={handleIsNewConversation}><i
                        className="fas fa-plus-circle"></i></button>
                </div>
            </div>
        </div>
    );
};

export default SideComponents;
