import { createContext, useEffect, useReducer } from "react";
import Reducer from "./Reducers";

const userFromLocalStorage = localStorage.getItem("user");
let initialUser;
try {
    initialUser = JSON.parse(userFromLocalStorage) || {};
} catch (error) {
    console.error("Error parsing user data from local storage:", error);
    initialUser = {}; // Provide an empty object as default
}

const INITIAL_STATE = {
    user: initialUser,
    isFetching: false,
    error: false,
};

export const Context = createContext(INITIAL_STATE);

export const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, INITIAL_STATE);

    useEffect(()=>{
        localStorage.setItem("user", JSON.stringify(state.user));
    }, [state.user])

    return (
        <Context.Provider
            value={{
                user: state.user,
                isFetching: state.isFetching,
                error: state.error,
                dispatch,
            }}
        >
            {children}
        </Context.Provider>
    );
};