import React from "react";
import warningAlert from "../../services/AlertS/warning";

export const ScoringFields = ({data, index, studentTakingCourse, setStudentTakingCourse}) => {
    const [totalAmount, setTotalAmount] = React.useState(data.total);

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target;
        const updatedData = {
            ...data,
            [name]: type === "checkbox" ? checked : Number(value)
        };

        const updatedStudentTakingCourse = studentTakingCourse.map((item) =>
            item.uuid === updatedData.uuid ? {
                ...item,
                ...updatedData,
            } : item
        );
        setTotalAmount(Number(updatedData.assignment) +
            Number(data.onlineexam ? data.onlinescore : updatedData.exam) +
            Number(data.onlinetest ? data.onlinetestscore : updatedData.test) +
            Number(updatedData.attendance)
        );
        setStudentTakingCourse(updatedStudentTakingCourse);
    };
    return (
        <tr>
            <td>{index}</td>
            <td>
                {data.firstname} {data.lastname} [{data.matricNo}]
            </td>
            <td>
                {!data.onlinetest ? (
                    <input
                        type="number"
                        className="form-control"
                        name="test"
                        value={data.test}
                        onChange={(e) => {
                            const value = Number(e.target.value);
                            if (value > 20) {
                                warningAlert('Exceeding Value', 'You exceeded the value limit for this field');
                            } else {
                                handleChange(e);
                            }
                        }}
                    />
                ) : (
                    data.onlinetestscore
                )}
            </td>
            <td>
                {!data.onlineexam ? (
                    <input
                        type="number"
                        className="form-control"
                        name="exam"
                        value={data.exam}
                        onChange={(e) => {
                            const value = Number(e.target.value);
                            if (value > 60) {
                                warningAlert('Exceeding Value', 'You exceeded the value limit for this field');
                            } else {
                                handleChange(e);
                            }
                        }}
                    />
                ) : (
                    data.onlinescore
                )}

            </td>
            <td>
                <input
                    type="number"
                    className="form-control"
                    name="attendance"
                    value={data.attendance}
                    onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value > 10) {
                            warningAlert('Exceeding Value', 'You exceeded the value limit for this field');
                        } else {
                            handleChange(e);
                        }
                    }}
                />
            </td>
            <td>
                <input
                    type="number"
                    className="form-control"
                    name="assignment"
                    value={data.assignment}
                    onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value > 10) {
                            warningAlert('Exceeding Value', 'You exceeded the value limit for this field');
                        } else {
                            handleChange(e);
                        }
                    }}
                />
            </td>
            <td>{totalAmount}</td>
        </tr>
    );
};

export default ScoringFields;
