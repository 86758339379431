export const Table = (personinfo) => {
	const buttons = [
		{
			label: '',
			className: 'btn btn-sm btn-primary',
			onClick: (id) => {
				// Handle button 1 click event
			},
		},
		// {
		//     label: 'Text',
		//     className: 'btn btn-sm btn-warning',
		//     onClick: () => {
		//         // Handle button 2 click event
		//     },
		// },
		// Add more buttons as needed
	];
	const formattedDate = (dateString) => {
		return (new Date(dateString).toLocaleDateString("en-US", {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
			})
		)
	}
	return [
		{
			cell: (row) => (
				// Render buttons within a cell
				<div>
					{/*{buttons.map((button, index) => (*/}
					{/*	// <button key={index} title="Details" className={button.className}*/}
					{/*	//    onClick={button.onClick(row.uuid)}>*/}
					{/*	// 	{button.label} <i className="las la-file-alt"></i>*/}
					{/*	// </button>*/}
					{/*))}*/}
				</div>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,

		},
		{
			name: 'Reference N°',
			selector: row => row.reference,
			sortable: true,
		},
		{
			name: 'Type',
			selector: row => <span className="text-uppercase">{row.transtype}</span>,
			sortable: true,
		},
		{
			name: 'Amount (#)',
			selector: row => (row.amount && parseInt(row.amount).toLocaleString()),
			sortable: true,
		},
		{
			name: 'Sender',
			selector: row => `${row.senderFirstname}  ${row.senderLastname}`,
			sortable: true,
		},
		{
			name: 'Reciever',
			selector: row => `${row.recieverFirstname} ${row.recieverLastname}`,
			sortable: true,
		},
		{
			name: 'BBT',
			selector: row => row.senderId === personinfo.id ?  (row.balanceBefore && row.balanceBefore.toLocaleString()) : (row.balanceBeforeReciever && row.balanceBeforeReciever.toLocaleString()),
			sortable: true,
		},
		{
			name: 'Issued on',
			selector: row => formattedDate(row.created),
			sortable: true,
		},
	];
}
export default Table;