import { useEffect } from "react";

const replaceLettersWithNumbers = (event) => {
    const input = event.target;
    const inputValue = input.value;
    const sanitizedValue = inputValue.replace(/[A-Za-z]/g, "");
    input.value = sanitizedValue;
};

export const useReplaceLettersWithNumbers = () => {
    useEffect(() => {
        const handleKeyUp = (event) => {
            const input = event.target;
            if (input.tagName === "INPUT" && input.classList.contains("numberField")) {
                replaceLettersWithNumbers(event);
            }
        };

        document.addEventListener("keyup", handleKeyUp);

        return () => {
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);
};
