import {useSelector} from "react-redux";
import setPageTitle from "../services/TitleService";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import React from "react";
import Content from "../components/AI/Content";
import UnathorizedRedirect from "../services/UnathorizedRedirect";

export const AiView = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('AI');
	UnathorizedRedirect(personinfo, ['Student','Lecturer'])
	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<Content personinfo={personinfo}/>
		</div>
	)
}

export default AiView;