import {useSelector} from "react-redux";
import setPageTitle from "../services/TitleService";
import LeftSide from "../components/Blocks/Leftside/LeftSide";
import Content from "../components/E_Library/Content";
import React from "react";
import UnathorizedRedirect from "../services/UnathorizedRedirect";

export const ELibrary = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('E-Library');
	UnathorizedRedirect(personinfo, ['Student','Lecturer'])
	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<Content personinfo={personinfo}/>
		</div>
	)
}
export default ELibrary;