import {useNavigate} from "react-router";
import React from "react";
import {Context} from "../../context/Contexts";
import { useDispatch } from "react-redux";
import { updateuserinfo, updateUserTheme } from "../../redux/userRedux";
export const Logout = () => {
    const {dispatch} = React.useContext(Context);
    const userDispatch = useDispatch();
    React.useEffect(() => {
    dispatch({type: "LOGOUT"})
    //     // Clear the userInfo value in the Redux store when the component mounts
        userDispatch(updateuserinfo(null));
    }, []);
    localStorage.clear();
    window.location.replace('/')

}
export default Logout;