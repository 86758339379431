import React from "react";
import API from "../../api";
import {Context} from "../../context/Contexts";
import TopMeunSection from "../../components/Blocks/TopMeunSection";
import AnsweredScript from "./AnsweredScript";

export const AssessmentScoreContent = ({view, personinfo, id, reference}) => {
    const {user} = React.useContext(Context)
    const headers = {
        Authorization: `Bearer ${user.token}`,
    };
    const [totalDuration, setTotalDuration] = React.useState(0);
    const [studentPoint, setStudentPoint] = React.useState(0);
    const [totalPoint, setTotalPoint] = React.useState(0);
    const [questionNotAnswes, setQuestionNotAnswered] = React.useState(0);
    const [assscript, setAssscript] = React.useState([]);
    const [assessement, setAssessement] = React.useState([]);
    React.useEffect(() => {
        const req = async (e) => {
            try {

                const res = await API.post('view_scores',
                    {id}, {headers})

                setAssessement(res.data.assessment)
                setAssscript(res.data.assesementScript)
                setQuestionNotAnswered(res.data.questionNotAnswered)
                setTotalPoint(res.data.totalpoint)
                setStudentPoint(res.data.studentpoint)
                setTotalDuration(res.data.totalduration)
            } catch (e) {

            }
        }
        req()
    }, [id]);
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}m:${seconds < 10 ? '0' + seconds : seconds}s`;
    };


    const groupedScripts = [];
    assscript.forEach((script) => {
        const groupDate = script.reference; // Extract the date part
        const existingGroup = groupedScripts.find((group) => group.referenceCode === groupDate);
        if (existingGroup) {
            existingGroup.scripts.push(script);
        } else {
            groupedScripts.push({referenceCode: groupDate, scripts: [script]});
        }
    });

    // const studentResponses = groupedScripts.map((scripts) => (
    //     <div className="p-4" key={scripts.referenceCode}>
    //         {/*<span className="badge bg-success mb-2">Exam reference: #{scripts.referenceCode}</span>*/}
    //         {scripts.scripts.map((item, index) => (
    //             <AnsweredScript key={index} info={item}/>
    //         ))}
    //     </div>
    // ));
    const checkQMarkedStatus = (data) => {
        return data.some((item) => item.qMarked === false);
    };
    return (
        <div className='content'>
            <TopMeunSection personinfo={personinfo}/>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <h3>My Score</h3>
                                <div className="mt-4">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-12 col-lg-6 text-center">
                                            <h1 className="fw-bolder">{studentPoint}</h1>
                                            <p className="mb-2">Your score</p>
                                            {checkQMarkedStatus(assscript) &&(
                                                <small className="text-info fw-bolder">Please note this is not you total
                                                    scores as there are some single question that require the lecturer to
                                                    manually grade. Contact the lecturer in charge of the course </small>

                                            )}

                                            <h3 className="my-2">{assessement.title}</h3>
                                            <h4 className="my-2 text-uppercase">{assessement.assessment}</h4>
                                            Assessment Duration: <b>{formatTime(totalDuration)}</b>
                                            <hr/>

                                        </div>
                                        <div className="col-sm-12 col-lg-6">
                                            <div className="p-4">
                                                <h2 className="text-success">{totalPoint}</h2>
                                                <span>Total point</span>
                                            </div>
                                            <div className="p-4">
                                                <h2 className="text-success">{assscript.length}</h2>
                                                <span>Total question</span>
                                            </div>
                                            <div className="p-4">
                                                <h2>{questionNotAnswes}</h2>
                                                <span>Question not answered</span>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentScoreContent;