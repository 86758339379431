import React from "react";
import {Context} from "../../context/Contexts";
import API from "../../api";

export const AffiliateDashboardSection = () => {
	const { user } = React.useContext(Context);
	const headers = {
		Authorization: `Bearer ${user.token}`,
	};

	const [affiliates, setAffiliates] = React.useState([]);

	React.useEffect(() => {
		const fetchAffiliateInfo = async () => {
			try {
				const res = await API.post("affiliate_info", {}, { headers });
				setAffiliates(res.data.list);
			} catch (e) {
				// Handle error
			}
		};
		fetchAffiliateInfo();
	}, []);
	return (
		<div className="card rewv4s mb-4">
			<div className="card-body sharesecetion">
				<h3 className="mb-3"><b>{affiliates.length}</b> Affiliates</h3>
				<a href="/my-affilitates" className="text-success">Click here to view full list</a>
			</div>
		</div>
	)
}
export default AffiliateDashboardSection;