import {useSelector} from "react-redux";
import setPageTitle from "../../services/TitleService";
import LeftSide from "../../components/Blocks/Leftside/LeftSide";
import React from "react";
import Content from "../../components/Fee&Transaction/Transaction/HistoryContent";

export const TransactionHistory = () => {
	const personinfo = useSelector(state => state.user.userInfo);
	setPageTitle('Transactions History');
	return (
		<div className="d-flex position-relative">
			<LeftSide personinfo={personinfo}/>
			<Content personinfo={personinfo}/>
		</div>
	)
}
export default TransactionHistory;